import { Button, Modal, TextAreaField, UnstyledButton } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import { useState } from 'react';
import { IncentiveForm } from '../Forms/IncentiveForm';
import { BsX } from 'react-icons/bs';

export const IncentiveDetailTab = () => {
  const [activeFlag, setActiveFlag] = useState(false);
  const [openReactivatemodal, setOpenReactivateModal] =
    useState<boolean>(false);
  const [countCharacter, setCountCharacter] = useState<number>(500);
  const handleInactivate = () => {
    setActiveFlag(false);
  };
  const handleDescriptionChange = (event: string) => {
    const maxLength = 500;
    setCountCharacter(maxLength - event.length);
  };

  return (
    <Page>
      <IncentiveForm />
      <Page.Section className="bg-gray-100">
        <div className="flex flex-col lg:flex-row lg:justify-end mt-6">
          {activeFlag ? (
            <div className="flex flex-col lg:flex-row gap-2">
              <Button variant="outline" onPressChange={handleInactivate}>
                Inactivate
              </Button>
              <Button variant="outline">Cancel</Button>
              <Button>Save</Button>
            </div>
          ) : (
            <Button onPress={() => setOpenReactivateModal(true)}>
              Reactivate
            </Button>
          )}
        </div>
        <div>
          <Modal
            ariaLabel="reactivate"
            isOpen={openReactivatemodal}
            className="w-full lg:w-2/5 h-3/4"
          >
            <Modal.Header>
              <h3>Reactivate Calculation Group</h3>
              <UnstyledButton>
                <BsX
                  size="2rem"
                  onClick={() => setOpenReactivateModal(false)}
                  className="cursor-pointer absolute sm:relative right-2 sm:right-0"
                />
              </UnstyledButton>
            </Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to reactivate the Calculation Group?</p>
              <div className="mt-4">
                <TextAreaField
                  rows={6}
                  placeholder="Describe reason"
                  onChange={(event) => handleDescriptionChange(event)}
                />
              </div>
            </Modal.Content>
            <p className="flex justify-end text-gray-400">
              {countCharacter} characters left
            </p>
            <Modal.Footer>
              <div className="flex flex-col lg:flex-row gap-2 mb-6 mt-2">
                <Button variant="outline">Cancel</Button>
                <Button>Reactivate</Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </Page.Section>
    </Page>
  );
};
