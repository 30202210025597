import { Button, Modal, TextAreaField, UnstyledButton } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import { IncentiveForm } from 'features/Incentive/components/Forms/IncentiveForm';
import { useState } from 'react';
import { BsX } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

export const AddCalcGroup = () => {
  const [openAddmodal, setOpenAddModal] = useState<boolean>(false);
  const [countCharacter, setCountCharacter] = useState<number>(0);
  const handleDescriptionChange = (event: string) => {
    setCountCharacter(event.length);
  };

  return (
    <Page
      breadcrumbs={[
        <NavLink key="ac-1" to="/incentives/incentive-table">
          Incentive
        </NavLink>,
        <NavLink key="ac-2" to="/incentives/incentive-table">
          Incentive Table
        </NavLink>,
      ]}
      heading={'Add Calculation Group'}
    >
      <Page.Section>
        <IncentiveForm />
        <div className="flex flex-col lg:flex-row gap-4 mt-4">
          <Button onPress={() => setOpenAddModal(true)}>Add</Button>
          <Button variant="outline">Cancel</Button>
        </div>
        <div>
          <Modal
            ariaLabel="reactivate"
            isOpen={openAddmodal}
            // size="default"
            className="w-full lg:w-2/5 lg:h-3/4"
          >
            <Modal.Header>
              <h3 className="flex item-center">Add Calculation Group</h3>
              <UnstyledButton>
                <BsX
                  size="1.5rem"
                  onClick={() => setOpenAddModal(false)}
                  className="flex justify-end cursor-pointer "
                />
              </UnstyledButton>
            </Modal.Header>
            <Modal.Content>
              <div className="text-sm w-full border-t border-gray-200">
                Are you sure you want to add the Calculation Group?
              </div>
              <div className="mt-2 ">
                <TextAreaField
                  rows={5}
                  placeholder="Describe reason"
                  onChange={(event) => handleDescriptionChange(event)}
                />
              </div>
            </Modal.Content>
            <div className="flex justify-end  text-gray-400">
              {countCharacter} / 500
            </div>
            <div className="w-full border-t border-gray-200"> </div>
            <Modal.Footer>
              <div className="flex flex-col lg:flex-row gap-2">
                <Button
                  variant="outline"
                  onPress={() => setOpenAddModal(false)}
                >
                  Cancel
                </Button>
                <Button>Add</Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </Page.Section>
    </Page>
  );
};
