import Page from 'components/Layouts/Page';
import { PositionCodeForm } from 'features/Position/Components/Forms/PostionCodeForm';
import { NavLink } from 'react-router-dom';

export const AddPositionCode = () => {
  return (
    <Page
      breadcrumbs={[
        <NavLink key="pc-1" to="/position/position-codes">
          Positions
        </NavLink>,
        <NavLink key="pc-1" to="/position/position-codes">
          Position codes
        </NavLink>,
      ]}
      heading={'Add Position Code'}
    >
      <Page.Section>
        <PositionCodeForm />
      </Page.Section>
    </Page>
  );
};
