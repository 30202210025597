import { Label, Select, TextField } from '@uss/react-core';
import Card from 'components/Card/Card';

export const IncentiveForm = () => {
  return (
    <Card>
      <Card.Body>
        <div className="flex items-center justify-center pb-6">
          <div className="grid lg:grid-cols-2 gap-4 sm:grid-cols-1">
            <Select
              className="mt-1.5"
              label="Payroll Location"
              placeholder="Select"
            />
            <div>
              <Label>
                <p className="text-xs text-gray-900">Model Description</p>
              </Label>
              <TextField
                type="text"
                aria-label="description"
                placeholder="Enter"
              />
            </div>
            <div>
              <Label>
                <p className="text-xs text-gray-900">Calculation Group</p>
              </Label>
              <TextField type="text" id="ac-2" placeholder="Enter" />
            </div>
            <div>
              <Label>
                <p className="text-xs text-gray-900">Application Code</p>
              </Label>
              <TextField type="text" id="ac-3" placeholder="Enter" />
            </div>
            <Select label="Dollar Code" placeholder="Select" />
            <Select label="Incentive Type" placeholder="Select" />
            <div>
              <Label>
                <p className="text-xs text-gray-900">Minimum Audit Incentive</p>
              </Label>
              <TextField type="text" id="ac-4" placeholder="Enter" />
            </div>
            <div>
              <Label>
                <p className="text-xs text-gray-900">Maximum Audit Incentive</p>
              </Label>
              <TextField type="text" id="ac-5" placeholder="Enter" />
            </div>
            <div>
              <Label>
                <p className="text-xs text-gray-900">
                  Minimum Payout Incentive
                </p>
              </Label>
              <TextField type="text" id="ac-5" placeholder="Enter" />
            </div>
            <div>
              <Label>
                <p className="text-xs text-gray-900">
                  Maximum Payout Incentive
                </p>
              </Label>
              <TextField type="text" id="ac-5" placeholder="Enter" />
            </div>
            <Select
              className="mt-1.5"
              label="Auto Code 6 Type"
              placeholder="Select"
            />
            <div>
              <Label>
                <p className="text-xs text-gray-900">Frozen Performance</p>
              </Label>
              <TextField type="text" id="ac-5" placeholder="Enter" />
            </div>

            <Select label="Responsibility 1" placeholder="Select" />
            <Select label="Responsibility 2 (Optional)" placeholder="Select" />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
