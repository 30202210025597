import { UnstyledLink } from '@uss/react-core';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import { NavLink } from 'react-router-dom';

export const PositionCodeCard = () => {
  return (
    <Card>
      <UnstyledLink
        as={NavLink}
        to={'/position/position-codes/position-code-detail'}
      >
        <Card.Body>
          <div className="font-semibold">012200</div>
          <div className="flex flex-row gap-2">
            <p className="">Updox Util Shake</p>
            <div className="border-r-2 border-gray-300" />
            <div>A10</div>
          </div>
          <div className="border-t-2 border-gray-300 mt-2" />
          <div className="flex flex-wrap justify-between">
            <div className="w-1/2">
              <LabeledText label="LG">1</LabeledText>
            </div>
            <div className="w-1/2">
              <LabeledText label="Position Type">P</LabeledText>
            </div>
            <div className="w-1/2">
              <LabeledText label="Division">Iron Producing</LabeledText>
            </div>
            <div className="w-1/2">
              <LabeledText label="Department">
                Recycle-Raw Mat&apos;l
              </LabeledText>
            </div>
            <div className="w-1/2">
              <LabeledText label="DRO Unit" lineClamp={2}>
                Recycle And Raw
              </LabeledText>
            </div>
            <div className="w-1/2">
              <LabeledText label="Calculation Group">70</LabeledText>
            </div>
          </div>
        </Card.Body>
      </UnstyledLink>
    </Card>
  );
};
