import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Label,
  NumberField,
  Select,
  SelectOptions,
} from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { useEditModel } from 'features/model-listing/api/mutations';
import { crewRotationItems } from 'features/model-listing/constants';
import { AddWeekCrewValidationSchema } from 'features/model-listing/schemas/edit-model';
import {
  AddWeekCrewPayload,
  EditModelReqBody,
  ModelDetailsResponse,
  ModelWeeks,
} from 'features/model-listing/types';
import { getEmptyWeekObject } from 'features/model-listing/utility/getEmptyWeekObject';
import { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { queryClient } from 'services/api/react-query';

interface AddWeeksCrewProps {
  data: ModelDetailsResponse | undefined;
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
}
export const AddWeeksCrew = ({ data, setOpenDrawer }: AddWeeksCrewProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddWeekCrewPayload>({
    mode: 'onSubmit',
    resolver: zodResolver(AddWeekCrewValidationSchema),
    defaultValues: {},
  });

  //watch states
  const selectedWeek = useWatch({ control, name: 'numberOfWeeks' });
  const selectedCrewRotation = useWatch({ control, name: 'crewRotation' });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const { mutateAsync: editModelMutateAsync } = useEditModel(data?.id ?? '');

  useEffect(() => {
    if (!(selectedWeek && selectedCrewRotation)) {
      setIsAddButtonDisabled(true);
    } else setIsAddButtonDisabled(false);
  }, [selectedCrewRotation, selectedWeek]);

  const handleChange = async () => {
    setIsAddButtonDisabled(true);

    const weekData: ModelWeeks = getEmptyWeekObject(
      Number(selectedCrewRotation)
    );

    const arr: ModelWeeks[] = [];
    if (selectedWeek) {
      for (let i = 0; i < selectedWeek; i++) {
        arr.push(weekData);
      }
    }
    let counter = 0;
    const allUpdatedWeeks = arr.map((week) => {
      counter++;
      return {
        weekNo: counter,
        crewRotations: week.crewRotations,
      };
    });
    const payload: EditModelReqBody = {
      modelDescription: data?.modelDescription ?? '',
      action: 'save',
      weeks: allUpdatedWeeks,
    };
    await editModelMutateAsync(payload, {
      onSuccess: async () => {
        setOpenDrawer(false);
        notify.success({
          message: `You have successfully added week(s)/crew(s).`,
        });
        await queryClient.resetQueries({
          queryKey: ['model-details'],
          type: 'active',
        });
      },
    });
  };

  return (
    <>
      <form
        className="flex flex-col flex-grow mt-4"
        onSubmit={handleSubmit(() => handleChange())}
      >
        <div className="flex flex-col flex-grow">
          <Controller
            control={control}
            name="numberOfWeeks"
            render={({ field, fieldState: { invalid, error } }) => (
              <div>
                <Label>
                  <p className="text-xs text-black">Number of Weeks</p>
                </Label>
                <NumberField
                  label=""
                  aria-label="weeks"
                  placeholder="Enter"
                  errorMessage={errors.numberOfWeeks?.message}
                  onChange={field.onChange}
                  isInvalid={invalid}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="crewRotation"
            render={({ field }) => (
              <div>
                <Label>
                  <p className="text-xs text-black mt-2">
                    Number of Crew Rotations
                  </p>
                </Label>

                <Select
                  items={crewRotationItems}
                  className="w-full"
                  selectedKey={selectedCrewRotation}
                  onSelectionChange={field.onChange}
                  placeholder="Select"
                  isRequired
                >
                  {(item) => (
                    <SelectOptions id={item.id}>{item.id}</SelectOptions>
                  )}
                </Select>
              </div>
            )}
          />
        </div>
        <div className="flex md:flex-row flex-col items-end justify-end mt-6 gap-2">
          <Button
            variant="outline"
            color={'primary'}
            full={true}
            type="button"
            onPress={() => setOpenDrawer(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'primary'}
            full={true}
            type={'submit'}
            isDisabled={isAddButtonDisabled}
          >
            Add
          </Button>
        </div>
      </form>
    </>
  );
};
