import { useMutation } from '@tanstack/react-query';
import { AddVariablePayload, UpdateVariablePayload } from '../types';
import { variables } from './api';

export const useAddVariable = () => {
  return useMutation({
    mutationFn: (body: AddVariablePayload) => variables.post(body),
  });
};

export const useUpdateVariable = (varId: string) => {
  return useMutation({
    mutationFn: (body: UpdateVariablePayload) => variables.put(varId, body),
  });
};
