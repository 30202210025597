import { NavLink } from 'react-router-dom';
import Page from 'components/Layouts/Page';
import { AutoSearch, Select } from '@uss/react-core';
import { BsArrowDownUp, BsFunnel, BsPlus } from 'react-icons/bs';
import { PositionCodeCard } from 'features/Position/Components/Cards/PositionCodeCard';
import { useModelList } from 'features/model-listing/api/queries';
import { useModelListFiltersStore } from 'features/model-listing/state/model-list-store';
import { useLoggedInUser } from 'features/people/api';
import Pagination from 'components/Pagination/Pagination';

export const PositionCode = () => {
  const { data: user } = useLoggedInUser();
  //getting logged-in user id
  const ussId = user ? user.ussId.toString() : '';
  //store
  const store = useModelListFiltersStore();
  //get model list with pagination

  const { data } = useModelList(ussId, { ...store.params });
  return (
    <>
      <Page
        breadcrumbs={[
          <NavLink key="pc-1" to="/position/position-codes">
            Positions
          </NavLink>,
        ]}
        heading={' Position Codes'}
      >
        <Page.Section bg="gray">
          <div className="flex flex-row md:gap-4 w-full">
            <div className="w-full md:w-1/2 pr-2">
              <AutoSearch placeholder="Search on this page" />
            </div>
            <div className="flex flex-row gap-4 flex-nowrap md:w-full justify-end">
              <BsFunnel size="1.5rem" className="text-primary-400" />
              <BsArrowDownUp size="1.5rem" className="text-primary-400" />
            </div>
          </div>
          <div className="flex flex-row mt-4 gap-4 flex-wrap md:flex-nowrap">
            <Select className="w-full" placeholder="select"></Select>
            <Select className="w-full" placeholder="select"></Select>
            <div className="flex lg:justify-end w-full">
              <NavLink
                className="flex flex-row "
                to={'/position/position-codes/add-position-code'}
              >
                <div className="flex flex-row items-center w-full justify-end text-primary-400 font-semibold mx-2">
                  <BsPlus size="2rem" />
                  <span>Add position code</span>
                </div>
              </NavLink>
            </div>
          </div>
          <div className=" grid grid-col-1 md:grid-cols-3 gap-4 my-4">
            <PositionCodeCard />
            <PositionCodeCard />
            <PositionCodeCard />
            <PositionCodeCard />
          </div>
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={data?.totalCount}
            page={Number(store.params.page)}
            pageSize={Number(store.params.pageSize)}
            onPageChange={(s) => store.setPage(s.selected + 1)}
            onPageSizeChange={(ps) => store.setPageSize(ps)}
          />
        </Page.Section>
      </Page>
    </>
  );
};
