import { Button, Checkbox, CheckboxGroup } from '@uss/react-core';
import { crewRotationItems } from 'features/model-listing/constants';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { EditModelReqBody } from 'features/model-listing/types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
interface DeleteCrewRotationsProps {
  setOpenDrawer: (val: boolean) => void;
  openDrawer: boolean;
  setOpenDeleteCrewRotation: (val: boolean) => void;
  setCrewRotationPayload: (val: EditModelReqBody) => void;
}

export const DeleteCrewRotations = ({
  setOpenDrawer,
  setOpenDeleteCrewRotation,
  setCrewRotationPayload,
}: DeleteCrewRotationsProps) => {
  const store = useModelDetailStore();
  const navigate = useNavigate();

  const [checked, setChecked] = useState<string[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

  const crewRotationArray = crewRotationItems.slice(
    0,
    store.modelDetail?.totalCrewRotations
  );
  const crewRotationArrayWithAll = [
    { id: 0, value: 'all' },
    ...crewRotationArray,
  ];
  const handleOnChange = (val: string[]) => {
    if (val.filter((e) => e !== 'all').length === crewRotationArray.length) {
      setIsSelectedAll(true);
      setChecked(['all', ...val]);
    } else {
      setIsSelectedAll(false);
      setChecked(val.filter((e) => e !== 'all'));
    }
  };
  const onSelectAllHandle = (val: boolean) => {
    if (val) {
      setChecked(crewRotationArrayWithAll.map((e) => e.value));
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
      setChecked([]);
    }
  };

  const handleDelete = () => {
    let payload: EditModelReqBody = {
      modelDescription: '',
      action: '',
    };
    if (
      checked.filter((e) => e !== 'all').length ===
      store.modelDetail?.totalCrewRotations
    ) {
      payload = {
        modelDescription: store.modelDetail.modelDescription,
        action: 'save',
        weeks: [],
      };
    } else {
      const allWeeks = store.modelDetail?.weeks.map((week) => {
        return {
          weekNo: week.weekNo,
          crewRotations: week.crewRotations.filter(
            (crew) => !checked.includes(crew.crewRotation)
          ),
        };
      });

      const weeks = allWeeks?.map((element) => {
        let counter = -1;
        return {
          weekNo: element.weekNo,
          crewRotations: element.crewRotations.map((crew) => {
            counter++;
            return {
              pkCrewRotationId: crew.pkCrewRotationId,
              crewRotation: crewRotationItems[counter].value,
              schedTurns: crew.schedTurns,
            };
          }),
        };
      });
      payload = {
        modelDescription: store.modelDetail?.modelDescription ?? '',
        action: 'save',
        weeks: weeks,
      };
    }
    setCrewRotationPayload(payload);
    setOpenDeleteCrewRotation(true);
  };
  const handleCancel = () => {
    setOpenDrawer(false);
    navigate(
      `/non-union-scheduling/modeling/${store.modelDetail?.id ?? ''}/edit-model`
    );
  };

  return (
    <>
      <form className="flex flex-col flex-grow">
        <div className="w-full flex flex-col flex-grow gap-2 ">
          <CheckboxGroup
            label=""
            aria-label="Select the crew(s) you want to delete"
            orientation="vertical"
            value={checked}
            onChange={(e) => handleOnChange(e)}
          >
            <Checkbox
              isIndeterminate={isSelectedAll}
              isSelected={isSelectedAll}
              value="all"
              onChange={(e) => onSelectAllHandle(e)}
            >
              Select All
            </Checkbox>
            {crewRotationArray.map((crewRotation, index) => (
              <Checkbox
                key={index}
                value={crewRotation.value}
              >{`Crew ${crewRotation.value}`}</Checkbox>
            ))}
          </CheckboxGroup>
        </div>

        <div className="flex justify-between gap-2 pt-6">
          <Button
            variant="outline"
            color={'primary'}
            full={true}
            type="button"
            onPressChange={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'danger'}
            full={true}
            type={'button'}
            onPress={handleDelete}
          >
            Delete
          </Button>
        </div>
      </form>
    </>
  );
};
