import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { IncentiveDetailsCard } from 'features/Incentive/components/IncentiveDetails/IncentiveDetailsCard';
import { IncentiveDetailTab } from 'features/Incentive/components/IncentiveDetails/IncentiveDetailTab';
import { NavLink } from 'react-router-dom';
import ComingSoon from 'views/ComingSoon';

export const IncentiveDetail = () => {
  const tabLinks = [
    {
      label: 'Details',
      element: <IncentiveDetailTab />,
    },
    {
      label: 'History',
      element: <ComingSoon />,
    },
  ];

  return (
    <>
      <Page
        breadcrumbs={[
          <NavLink key="a-1" to="/incentives/incentive-table">
            Incentives
          </NavLink>,
          <NavLink key="a-2" to="/incentives/incentive-table">
            Incentive Table
          </NavLink>,
        ]}
        heading={'Calculation Group Details'}
      >
        <Page.Section>
          <IncentiveDetailsCard />
        </Page.Section>
        <Page.Section>
          <Tabs tabLinks={tabLinks} />
        </Page.Section>
      </Page>
    </>
  );
};
