/**
 * Creates an adjustment for existing timecard
 *
 * @param ussId USS ID of the employee
 * @returns React Query useMutation Result
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/schedule-pay-controller/saveEmployeeTimecard
 */

import useNotification from 'components/Notification/useNotification';
import { useMutation } from '@tanstack/react-query';
import { axios } from 'services/api/axios';
import {
  AddAdjustmentPayload,
  EmployeeTimecard,
} from 'features/timecards/types';
import { queryClient } from 'services/api/react-query';

/**
 * Endpoint to create a new timecard adjustment for an employee
 *
 * @param ussId USS Id of the person
 * @param body Updated timecard object
 * @returns Promise
 */
const create_adjustment = (
  ussId: string,
  body: AddAdjustmentPayload
): Promise<EmployeeTimecard> => {
  return axios.post(`/v1/people/${ussId}/timecard-details`, body);
};

export const useCreateAdjustment = (
  ussId: string,
  currentAdjustment: number,
  setAdjustment?: (value: number | null) => void
) => {
  const notify = useNotification();

  return useMutation({
    mutationFn: (body: AddAdjustmentPayload) => create_adjustment(ussId, body),

    onSuccess: (response, body) => {
      if (response.resetInactiveDays) {
        notify.success({
          message:
            'You have successfully created an adjustment for this employee. Any days the employee is Inactive on have been reset.',
        });
      } else {
        notify.success({
          message:
            'You have successfully created an adjustment for this employee.',
        });
      }
      queryClient.removeQueries({ queryKey: ['employee-timecard'] });
      setAdjustment?.(currentAdjustment + 1);
    },
  });
};
