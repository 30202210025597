import { Button, MenuTrigger, Popover } from '@uss/react-core';
import { MODEL_STATUS, MODEL_TYPE } from 'features/model-listing/constants';
import { ModelDetailsResponse } from 'features/model-listing/types';
import { getModelActionButton } from 'features/model-listing/utility/getModelActionButton';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { WeekCard } from './WeekCard';
import Pagination from 'components/Pagination/Pagination';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { useNavigate } from 'react-router-dom';
import { useCheckoutModel } from 'features/model-listing/api/mutations';
import { useState } from 'react';
import { BiChevronUp } from 'react-icons/bi';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';
import { CopyModelForm } from '../Forms/CopyModelForm';
import ConfirmationPopup from './ConfirmationPopup';
import EmptyIllustrationsSvg from 'components/svgs/EmptyIllustrationsSvg';
import { TransferOwnershipDrawer } from './TransferOwnershipDrawer';
export interface ModelDetailProps {
  data: ModelDetailsResponse;
}
export const ModelDetailTab = ({ data }: ModelDetailProps) => {
  const { roles, uss_id } = useUserRoles();
  const store = useModelDetailStore();
  const [openCopyModel, setOpenCopyModel] = useState<boolean>(false);

  const { mutateAsync } = useCheckoutModel(data.id);
  const navigate = useNavigate();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isRestoreModelOpen, setIsRestoreModelOpen] = useState(false);
  const [isMoreButtonOpen, setIsMoreButtonOpen] = useState<boolean>(false);
  const [isTransferOwnershipOpen, setIsTransferOwnership] =
    useState<boolean>(false);
  const { archiveButton, editButton, copyButton, restoreButton, moreButton } =
    getModelActionButton({
      data,
      roles,
      ussId: uss_id,
    });
  const isModelOwner = data.owner.ussId.toString() === uss_id;
  const isDisabled = (actionBtn: string) => {
    switch (actionBtn) {
      case 'archiveButton': {
        if (data.modelType === MODEL_TYPE.GLOBAL) {
          if (
            roles.includes(ROLES.SUPER_ADMIN) ||
            roles.includes(ROLES.SUPER_PAYROLL_ADMIN)
          )
            return false;
          else return true;
        } else {
          if (data.modelStatus === MODEL_STATUS.CHECKED_OUT) {
            if (data.lastCheckoutUssId === String(uss_id)) return false;
            else return true;
          } else if (roles.includes(ROLES.PAY_APPROVER))
            return isModelOwner ? false : true;
        }
        break;
      }
      case 'editButton': {
        if (data.modelType === MODEL_TYPE.GLOBAL) {
          if (
            roles.includes(ROLES.SUPER_ADMIN) ||
            roles.includes(ROLES.SUPER_PAYROLL_ADMIN)
          )
            return false;
          else return true;
        } else {
          if (roles.includes(ROLES.PAY_APPROVER)) {
            return isModelOwner ? false : true;
          } else {
            return false;
          }
        }
        break;
      }
      case 'moreButton': {
        if (data.modelType === MODEL_TYPE.GLOBAL) {
          if (
            roles.includes(ROLES.SUPER_ADMIN) ||
            roles.includes(ROLES.SUPER_PAYROLL_ADMIN)
          )
            return false;
          else return true;
        } else {
          if (roles.includes(ROLES.PAY_APPROVER)) {
            return isModelOwner ? false : true;
          } else {
            return false;
          }
        }
        break;
      }
      case 'copyButton': {
        if (data.modelType === MODEL_TYPE.GLOBAL) {
          if (
            roles.includes(ROLES.SUPER_ADMIN) ||
            roles.includes(ROLES.SUPER_PAYROLL_ADMIN)
          )
            return false;
          else {
            return true;
          }
        } else {
          return false;
        }
        break;
      }
      case 'restoreButton': {
        if (data.modelType === MODEL_TYPE.GLOBAL) {
          if (
            roles.includes(ROLES.SUPER_ADMIN) ||
            roles.includes(ROLES.SUPER_PAYROLL_ADMIN)
          )
            return false;
          else return true;
        } else {
          if (roles.includes(ROLES.PAY_APPROVER)) {
            return isModelOwner ? false : true;
          } else {
            return false;
          }
        }
        break;
      }
      default:
        return false;
    }
  };

  const handleEditClick = async () => {
    //enabling edit for Active-models only
    if (data.modelStatus === MODEL_STATUS.ACTIVE) {
      await mutateAsync(
        {
          action: 'checkout',
        },
        {
          onSuccess: () => {
            navigate('edit-model');
          },
        }
      );
    } else {
      navigate('edit-model');
    }
  };

  const handleArchive = async () => {
    await mutateAsync(
      {
        action: 'archive',
      },
      {
        onSuccess: async () => {
          setIsArchiveModalOpen(false);
          notify.success({
            message: 'You have successfully archived this model.',
          });
          await queryClient.resetQueries({
            queryKey: ['model-details'],
            type: 'active',
          });
        },
      }
    );
  };

  const handleRestore = async () => {
    await mutateAsync(
      {
        action: 'restore',
      },
      {
        onSuccess: async () => {
          setIsRestoreModelOpen(false);
          notify.success({
            message: 'You have successfully restored this model.',
          });
          await queryClient.resetQueries({
            queryKey: ['model-details'],
            type: 'active',
          });
        },
      }
    );
  };

  const handleArchiveMoreClose = () => {
    setIsMoreButtonOpen(false);
    return true;
  };

  return (
    <>
      <div>
        {data.totalWeeks === 0 && (
          <div className=" flex flex-col justify-center items-center">
            <EmptyIllustrationsSvg />
            <div className=" text-sm font-normal">{`Model has no weeks or crew rotations entered. Select 'Edit' to begin building the model.`}</div>
          </div>
        )}
      </div>
      <div className=" grid grid-cols-2 sm:grid-cols-3 2xl:grid-cols-4 gap-4 mb-4">
        {data.weeks.map((week, index) => {
          return (
            <WeekCard
              weekData={week}
              modelName={data.modelName}
              weekBeginIndex={data.weekBeginIndex}
              totalWeeks={data.totalWeeks}
              key={index}
            />
          );
        })}
      </div>
      <Pagination
        pageOptions={[9, 18, 27, 36, 45]}
        totalCount={data.totalWeeks}
        page={data.page}
        pageSize={data.pageSize}
        onPageChange={(s) => store.setPage(s.selected + 1)}
        onPageSizeChange={(ps) => store.setPageSize(ps)}
      />

      <div className="flex justify-end flex-wrap gap-2 mt-5">
        {moreButton && (
          <MenuTrigger isOpen={isMoreButtonOpen}>
            <Button
              variant={'outline'}
              onPress={() => setIsMoreButtonOpen(!isMoreButtonOpen)}
              isDisabled={isDisabled('moreButton')}
            >
              More <BiChevronUp size="1.25rem" className="ml-2" />
            </Button>
            <Popover
              placement="top"
              isOpen={isMoreButtonOpen}
              shouldCloseOnInteractOutside={handleArchiveMoreClose}
              className={`ml-8`}
            >
              <div className="flex-col">
                <div className="w-full hover:bg-blue-50 ">
                  <Button
                    variant={'subtle'}
                    onPress={() => {
                      setIsArchiveModalOpen(true);
                      setIsMoreButtonOpen(false);
                    }}
                    isDisabled={isDisabled('archiveButton')}
                  >
                    Archive
                  </Button>
                </div>

                <Button
                  variant={'subtle'}
                  onPress={() => {
                    setIsMoreButtonOpen(false);
                    setIsTransferOwnership(true);
                  }}
                >
                  Transfer Ownership
                </Button>
              </div>
            </Popover>
          </MenuTrigger>
        )}
        {archiveButton && (
          <Button
            variant="outline"
            color={'primary'}
            type="button"
            isDisabled={isDisabled('archiveButton')}
            onPress={() => setIsArchiveModalOpen(true)}
          >
            Archive
          </Button>
        )}
        {restoreButton && (
          <Button
            variant="outline"
            color={'primary'}
            type="button"
            isDisabled={isDisabled('restoreButton')}
            onPress={() => setIsRestoreModelOpen(true)}
          >
            Restore
          </Button>
        )}
        {editButton && (
          <Button
            variant={copyButton ? 'outline' : 'filled'}
            color={'primary'}
            type="button"
            onPress={handleEditClick}
            isDisabled={isDisabled('editButton')}
          >
            Edit
          </Button>
        )}

        {copyButton && (
          <Button
            variant="filled"
            color={'primary'}
            type="button"
            isDisabled={isDisabled('copyButton')}
            onPress={() => setOpenCopyModel(true)}
          >
            Copy
          </Button>
        )}
        {openCopyModel && (
          <CopyModelForm
            isOpenDrawer={openCopyModel}
            setOpenCopyModel={setOpenCopyModel}
            modelDetails={data}
          />
        )}
        {/* {editButton && data.modelStatus !== MODEL_STATUS.ACTIVE && (
          <Button
            variant={'filled'}
            color={'primary'}
            type="button"
            onPress={handleEditClick}
            isDisabled={isDisabled('editButton')}
          >
            Edit
          </Button>
        )} */}

        <ConfirmationPopup
          isOpen={isArchiveModalOpen}
          title={'Archive the Model?'}
          message={'Are you sure you want to archive this model?'}
          submitButtonText={'Continue'}
          handleAction={handleArchive}
          setIsModelOpen={setIsArchiveModalOpen}
        />
        <ConfirmationPopup
          isOpen={isRestoreModelOpen}
          title={'Restore the Model?'}
          message={'Are you sure you want to restore this model?'}
          submitButtonText={'Restore'}
          handleAction={handleRestore}
          setIsModelOpen={setIsRestoreModelOpen}
        />
        <TransferOwnershipDrawer
          openDrawer={isTransferOwnershipOpen}
          setOpenDrawer={setIsTransferOwnership}
          modelId={data.id}
          modelDetails={data}
        />
      </div>
    </>
  );
};
