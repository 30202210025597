import { INCENTIVE_STATUS } from '../constants';
import { IncentiveColor } from '../types/types';

//create badge color according to status
export const getIncentiveStatusColors = (value: string): IncentiveColor => {
  switch (value) {
    case INCENTIVE_STATUS.ACTIVE: {
      return 'green';
    }

    case INCENTIVE_STATUS.INACTIVE: {
      return 'yellow';
    }

    default:
      return 'green';
  }
};
