import { ModelWeeks } from '../types';

export const CheckIsTurnsEmpty = (weeks: ModelWeeks[]) => {
  // return true if week is empty
  const isWeekEmpty = weeks
    .map((week) =>
      week.crewRotations
        .flatMap((crew) => crew.schedTurns)
        .some((sch) => sch.turnValue !== 0)
    )
    .includes(false);

  return isWeekEmpty;
};
