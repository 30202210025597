import CardList from 'components/Card/CardList';
import CardsLoader from 'components/Loaders/CardsLoader';
import Pagination from 'components/Pagination/Pagination';
import { useIncentiveList } from 'features/Incentive/api/queries';
import { useIncentiveListStore } from 'features/Incentive/state/incentive-list-store';
import { IncentiveCard } from '../Cards/IncentiveCard';

export default function IncentiveListing() {
  //store
  const store = useIncentiveListStore();

  //get incentive list with pagination
  const { data, status } = useIncentiveList({ ...store.params });

  return (
    <div className="grid grid-cols-1 gap-4">
      {status === 'pending' && (
        <CardsLoader variant="row-grid" count={9} hasAvatar />
      )}
      {status === 'success' && (
        <>
          <CardList
            items={data?.items ?? []}
            variant="row-grid"
            renderItem={(listItem) => (
              <IncentiveCard incentiveListItem={listItem} />
            )}
            noResults={{
              title: 'No Records Found',
              description:
                'No incentive records found, Please modify the search criteria to view the records',
            }}
          />
          <Pagination
            pageOptions={[9, 18, 27, 36, 45, 54]}
            totalCount={data?.totalCount}
            page={Number(store.params.page)}
            pageSize={Number(store.params.pageSize)}
            onPageChange={(s) => store.setPage(s.selected + 1)}
            onPageSizeChange={(ps) => store.setPageSize(ps)}
          />
        </>
      )}
    </div>
  );
}
