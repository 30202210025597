import { Autocomplete } from '@uss/react-components';
import {
  Button,
  IconButton,
  Label,
  Modal,
  Select,
  SelectOptions,
  Sidepane,
  UnstyledButton,
} from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { useTransferOwner } from 'features/model-listing/api/mutations';
import { ModelDetailsResponse, Plant } from 'features/model-listing/types';
import { getPlantList } from 'features/model-listing/utility/getPlantList';
import { Org, ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api/getEmployeeOrgs';
import { PeopleOptions, Person } from 'features/people';
import { useSearchPeople } from 'features/people/api';
import { Key, useState, useEffect } from 'react';
import { BsX } from 'react-icons/bs';
import { queryClient } from 'services/api/react-query';
import { SuggestionsList } from './SuggestionList';
interface TransferOwnershipDrawerType {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
  modelId: string;
  modelDetails: ModelDetailsResponse;
}
export const TransferOwnershipDrawer = ({
  openDrawer,
  setOpenDrawer,
  modelId,
  modelDetails,
}: TransferOwnershipDrawerType) => {
  const { data: plantList } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
  });
  const { mutateAsync } = useTransferOwner(modelId);
  //filtered options array for plant & payroll
  const [plantListArr, setPlantListArr] = useState<Plant[] | []>([]);
  const [plant, setPlant] = useState<Org | null>(null);
  const [plantOrgId, setPlantOrgId] = useState<string | null>('all');
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<Person | null>(null);
  const [isTransferModelOpoen, setIsTransferModelOpen] =
    useState<boolean>(false);
  const searchPayload: PeopleOptions = {
    search: inputValue,
    page: '1',
    pageSize: '50',
    plant: plantOrgId !== 'all' ? plant?.plantCode : null,
  };

  const { data: filteredOptions, status } = useSearchPeople(searchPayload);

  const handlePlantSelect = (val: Key) => {
    setPlant(plantList?.items.find((e) => e.id === val) ?? null);
    setPlantOrgId(val.toString());
  };
  useEffect(() => {
    if (plantList?.items) {
      const plantArr = getPlantList(plantList, 'transferOwner');
      setPlantListArr(plantArr);
    }
  }, [plantList]);

  const clearAll = () => {
    setInputValue(() => '');
    setSelectedOption(null);
  };
  const onCancelSearch = (): void => {
    clearAll();
  };
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value === '') {
      clearAll();
    }
  };
  const handleSelect = (option: Person): void => {
    setInputValue(option.lastName);
    setSelectedOption(option);
  };
  const handleTransferModel = async () => {
    await mutateAsync(
      {
        newOwner: selectedOption?.ussId.toString() ?? '',
      },
      {
        onSuccess: async () => {
          setOpenDrawer(false);
          notify.success({
            message:
              'You have successfully transferred the ownership of this model.',
          });
          await queryClient.resetQueries({
            queryKey: ['model-details'],
            type: 'active',
          });
        },
      }
    );
  };
  return (
    <>
      <Sidepane
        ariaLabel={''}
        isOpen={openDrawer}
        onOpenChange={setOpenDrawer}
        className="w-full sm:w-3/12 md:w-3/12"
      >
        <Sidepane.Header className="flex  pb-7">
          <div className="flex text-xl font-semibold flex-grow">
            Transfer Model Ownership
          </div>
          <IconButton onPress={() => setOpenDrawer(false)}>
            <BsX className="text-gray-600" />
          </IconButton>
        </Sidepane.Header>
        <div className="flex flex-col flex-grow">
          <div className=" text-sm ">
            <p>Model Owners are capable of doing the following:</p>
            <p className="pl-1"> 1. Edit their own model</p>
            <p className="pl-1"> 2. Transfer model ownership to others</p>

            <p className="pt-3 text-xs">Current Model Owner </p>
          </div>
          <p className=" font-semibold pb-3">{` ${modelDetails.owner.displayName}`}</p>
          <Select
            items={plantListArr}
            selectedKey={plantOrgId}
            onSelectionChange={handlePlantSelect}
            label="Plant"
            className="w-full"
            isInvalid={plantOrgId === null}
          >
            {(item) => (
              <SelectOptions id={item.id}>{`${item.plant}`}</SelectOptions>
            )}
          </Select>

          <div className="py-2 ">
            <Label>Tranfer Model Ownership to</Label>
            <Autocomplete
              onClose={onCancelSearch}
              label="Tranfer Model Ownership to"
              value={inputValue}
              onChange={handleFilter}
              placeholder="Search text"
            >
              <SuggestionsList
                numberOfSearchChars={inputValue.length}
                options={filteredOptions}
                onSelect={handleSelect}
                status={status}
              />
            </Autocomplete>
          </div>
          {selectedOption && (
            <div className="py-2 grid grid-cols-2 ">
              <div className=" col-span-2 flex flex-col">
                <span className="text-base font-semibold text-gray-700">
                  {selectedOption.displayName}
                </span>
                <span className="text-xs text-gray-700 flex">
                  {selectedOption.ussId}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between gap-2 pt-6">
          <Button
            variant="outline"
            color={'primary'}
            full={true}
            type="button"
            onPress={() => setOpenDrawer(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={'primary'}
            full={true}
            type={'button'}
            onPressChange={() => setIsTransferModelOpen(true)}
            isDisabled={!selectedOption}
          >
            Transfer
          </Button>
        </div>
      </Sidepane>
      <Modal
        ariaLabel={'Transfer Ownership'}
        isOpen={isTransferModelOpoen}
        size="default"
      >
        <Modal.Header>
          <div className="flex flex-grow items-center ">
            <h2>Transfer Ownership</h2>
          </div>
          <UnstyledButton>
            <BsX
              size="2rem"
              onClick={() => setIsTransferModelOpen(false)}
              className="cursor-pointer absolute sm:relative right-2 sm:right-0"
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          Are you sure you want to transfer the ownership?
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-2">
            <Button
              variant={'outline'}
              onPress={() => setIsTransferModelOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant={'filled'}
              color={'primary'}
              onPress={() => handleTransferModel()}
            >
              Transfer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
