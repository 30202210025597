import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { PositionGeneralDetail } from 'features/Position/Components/PositionDetails/PositionGeneralDetail';
import { NavLink } from 'react-router-dom';
import ComingSoon from 'views/ComingSoon';

export const PositionCodeDetail = () => {
  const tabLinks = [
    {
      label: 'General Details',
      element: <PositionGeneralDetail />,
    },
    {
      label: 'Position History',
      element: <ComingSoon />,
    },
    {
      label: 'Craft',
      element: <ComingSoon />,
    },
    {
      label: 'Individual Rate',
      element: <ComingSoon />,
    },
  ];
  return (
    <Page
      breadcrumbs={[
        <NavLink key="pc-1" to="/position/position-codes">
          Positions
        </NavLink>,
      ]}
      heading={'Details : 012200'}
    >
      <Page.Section>
        <Tabs tabLinks={tabLinks} />
      </Page.Section>
    </Page>
  );
};
