import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ApiBasicStatusResponse,
  ApiBlackoutStatusResponse,
  WMSError,
} from 'types/common';

import {
  ActionButton,
  AddBlackoutPost,
  AddPtoPost,
  PtoDetail,
  PtoSellBack,
  RequestPtoPost,
  UpdatePtoRequestPut,
  DeleteEmployeesBlackout,
} from '../types';
import { notify } from 'components/Notification/useNotification';
import {
  addBlackoutRecord,
  addPtoRecord,
  ptoBlackout,
  ptoRecord,
  sellBack,
} from './api';
import { SellBackPtoPost, SellBackPtoPut } from '../types/sellback';
import { queryClient } from 'services/api/react-query';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { GetCrewParamsBody, GetOrgDetailsParamsBody } from '../types/crew-pto';

// ** PTO RECORDS *********

export const useAddPtoRequest = () => {
  const state = useNonUnionStore();
  const url = useLocation();
  return useMutation<ApiBasicStatusResponse, WMSError, RequestPtoPost>({
    mutationFn: (body: RequestPtoPost) => ptoRecord.post(body),
    onSuccess: async () => {
      //refetching timecard API on Pay-Approvals(NU) Screen, Scheduling Screen, My-Schedules Screen
      if (
        (state.feature === 'pay' ||
          state.feature === 'scheduling' ||
          state.feature === 'my-schedule') &&
        !url.pathname.includes('my-pto-nu')
      ) {
        let successMsg = 'You have successfully Added PTO.';
        if (state.feature === 'my-schedule')
          successMsg = 'You have successfully Requested PTO.';
        notify.success({
          message: successMsg,
        });

        if (url.pathname.includes(`paid-time-off/employees`)) {
          await queryClient.refetchQueries({
            queryKey: ['employee-timecard'],
          });
        } else
          await queryClient.resetQueries({
            queryKey: ['employee-timecard'],
            type: 'active',
          });
      }
    },
  });
};

//Add PTO (For Employee Search)
export const useAddPto = () => {
  const state = useNonUnionStore();
  const url = useLocation();
  return useMutation<ApiBasicStatusResponse, WMSError, AddPtoPost>({
    mutationFn: (body: AddPtoPost) => addPtoRecord.post(body),
    onSuccess: async (response, request) => {
      if (
        state.feature === 'pay' ||
        state.feature === 'scheduling' ||
        state.feature === 'my-schedule'
      ) {
        if (url.pathname.includes(`paid-time-off/employees`)) {
          await queryClient.refetchQueries({
            queryKey: ['employee-timecard'],
          });
        } else
          await queryClient.resetQueries({
            queryKey: ['employee-timecard'],
            type: 'active',
          });
      }
      await queryClient.refetchQueries({ queryKey: ['pto-details'] });
    },
  });
};

//Add Blackout
export const useAddBlackout = (
  employeeId: string[],
  userName: string,
  date: string
) => {
  const navigate = useNavigate();
  const url = useLocation();
  return useMutation<ApiBasicStatusResponse, WMSError, AddBlackoutPost>({
    mutationFn: (body: AddBlackoutPost) => addBlackoutRecord.post(body),
    onSuccess: () => {
      notify.success({
        message: `You have added Blackout for ${date} for ${userName}`,
      });
      if (url.pathname.includes('add-pto') && employeeId[0]) {
        navigate(`/paid-time-off/employees/${employeeId[0]}`);
      }
    },
  });
};

export const useDeletePtoRequest = (ptoId: string, employeeId: string) => {
  const state = useNonUnionStore();
  const url = useLocation();
  return useMutation<{
    success: boolean;
    message: string;
  }>({
    mutationFn: () => ptoRecord.delete(ptoId),
    onSuccess: async (response, request) => {
      notify.success({
        message: 'You have removed PTO for selected employee.',
      });
      await queryClient.refetchQueries({
        queryKey: ['crew-pto-details'],
        type: 'active',
      });
      await queryClient.refetchQueries({
        queryKey: ['crew-pto-totals'],
        type: 'active',
      });
      //refreshing data after deleting record for Requests(NU) screen
      await queryClient.refetchQueries({
        queryKey: ['pto-requests'],
        type: 'active',
      });
      if (
        state.feature === 'pay' ||
        state.feature === 'scheduling' ||
        state.feature === 'my-schedule'
      ) {
        if (url.pathname.includes(`paid-time-off/employees`)) {
          await queryClient.refetchQueries({
            queryKey: ['employee-timecard'],
          });
        } else
          await queryClient.resetQueries({
            queryKey: ['employee-timecard'],
            type: 'active',
          });
      }

      await queryClient.refetchQueries({ queryKey: ['pto-details'] });
    },
  });
};

export const usePutCrewPtoRecord = (
  ptoId: string,
  buttonRef: ActionButton,
  detailsRequest?: GetOrgDetailsParamsBody,
  totalsRequest?: GetCrewParamsBody
) => {
  return useMutation({
    mutationFn: (body: UpdatePtoRequestPut) => ptoRecord.put(ptoId, body),
    onSuccess: async () => {
      notify.success({
        message: `You have ${buttonRef} a PTO record.`,
      });
      await queryClient.refetchQueries({
        queryKey: [
          'crew-pto-details',
          { ...detailsRequest?.body, ...detailsRequest?.params },
        ],
      });
      await queryClient.refetchQueries({
        queryKey: [
          'crew-pto-totals',
          { ...totalsRequest?.body, ...totalsRequest?.params },
        ],
      });
    },
  });
};
export const usePutRequestPtoRecord = (
  ptoId: string,
  buttonRef: ActionButton
) => {
  return useMutation({
    mutationFn: (body: UpdatePtoRequestPut) => ptoRecord.put(ptoId, body),
    onSuccess: async () => {
      notify.success({
        message: `You have ${buttonRef} a PTO record.`,
      });
      await queryClient.refetchQueries({
        queryKey: ['pto-requests'],
        type: 'active',
      });
    },
  });
};

export const useUpdatePtoRequest = (
  ptoId: string,
  employeeId: string | undefined
) => {
  const navigate = useNavigate();
  const url = useLocation();
  const state = useNonUnionStore();
  return useMutation<PtoDetail, WMSError, UpdatePtoRequestPut>({
    mutationFn: (body: UpdatePtoRequestPut) => ptoRecord.put(ptoId, body),
    onSuccess: async (response, request) => {
      await queryClient.refetchQueries({
        queryKey: [`pto-id-${ptoId}`, ptoId],
      });
      if (response.status === 'approved') {
        notify.success({
          message: 'You have successfully saved changes to PTO.',
        });
      } else if (response.status === 'denied') {
        notify.success({
          message:
            'You have successfully saved the changes and the request has been denied.',
        });
      } else {
        notify.success({
          message:
            'You have successfully saved the changes and the request has been updated.',
        });
      }

      //For Timecard Ellipsis Edit PTO
      //refetching timecard API on Pay-Approvals(NU) Screen, Scheduling Screen, My-Schedules Screen
      if (
        state.feature === 'pay' ||
        state.feature === 'scheduling' ||
        state.feature === 'my-schedule'
      ) {
        if (url.pathname.includes(`paid-time-off/employees`)) {
          await queryClient.refetchQueries({
            queryKey: ['employee-timecard'],
          });
        } else
          await queryClient.resetQueries({
            queryKey: ['employee-timecard'],
            type: 'active',
          });
      }

      //For My PTO(NU) and Employee Search(NU) Screens
      if (url.pathname.includes('my-pto-nu')) {
        navigate('/my-pto-nu');
      } else if (url.pathname.includes('employees') && employeeId) {
        navigate(`/paid-time-off/employees/${employeeId}`);
      }
    },
  });
};

// ****************************

// ** Sell Back PTO

export const useAddSellBackPto = (ussId: string) => {
  return useMutation<PtoSellBack, WMSError, SellBackPtoPost>({
    mutationFn: (body: SellBackPtoPost) => sellBack.post(ussId, body),
    meta: {
      errorMessage: 'Unable to add new sell back pto.',
    },
  });
};

export const useUpdateSellBackPto = (ussId: string, id: string) => {
  return useMutation<PtoSellBack, WMSError, SellBackPtoPut>({
    mutationFn: (body: SellBackPtoPut) => sellBack.put(ussId, id, body),
    meta: {
      errorMessage: 'Unable to update sell back pto.',
    },
  });
};

// *********

// *********

// ** PTO BLACKOUT

export const useAddPtoBlackout = (
  detailsRequest?: GetOrgDetailsParamsBody,
  totalsRequest?: GetCrewParamsBody
) => {
  return useMutation<ApiBlackoutStatusResponse, WMSError, AddBlackoutPost>({
    mutationFn: (body: AddBlackoutPost) => ptoBlackout.post(body),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [
          'crew-pto-details',
          { ...detailsRequest?.body, ...detailsRequest?.params },
        ],
      });
      await queryClient.refetchQueries({
        queryKey: [
          'crew-pto-totals',
          { ...totalsRequest?.body, ...totalsRequest?.params },
        ],
      });
    },
    meta: {
      errorMessage: 'Unable to add pto blackout.',
    },
  });
};

export const useRemovePtoBlackout = (
  detailsRequest?: GetOrgDetailsParamsBody,
  totalsRequest?: GetCrewParamsBody,
  ussIds?: string[]
) => {
  return useMutation<ApiBasicStatusResponse, WMSError, DeleteEmployeesBlackout>(
    {
      mutationFn: (body: DeleteEmployeesBlackout) => ptoBlackout.delete(body),

      onSuccess: async (success) => {
        const message = ussIds
          ? 'You have removed PTO for selected employees'
          : 'You have removed PTO for selected employee';
        if (success.status === 206) {
          notify.warning({
            message: `Blackout removed for some employees but not for those who you do not have Blackout permissions for`,
          });
        } else {
          notify.success({
            message: message,
          });
        }
        await queryClient.refetchQueries({
          queryKey: [
            'crew-pto-details',
            { ...detailsRequest?.body, ...detailsRequest?.params },
          ],
        });
        await queryClient.refetchQueries({
          queryKey: [
            'crew-pto-totals',
            { ...totalsRequest?.body, ...totalsRequest?.params },
          ],
        });
      },
      meta: {
        errorMessage: 'Unable to delete pto blackout.',
      },
    }
  );
};

// *********
