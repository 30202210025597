import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { CategoryCodeListParamsOptions, TableList } from '../types';

interface VariableStore {
  params: CategoryCodeListParamsOptions;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  categoryList: TableList | null;

  setCategoryList: (value: TableList) => void;
}

export const useVariableStore = create<VariableStore>()(
  devtools(
    immer((set) => ({
      categoryList: null,
      params: {
        page: '1',
        pageSize: '9',
        varDefinitionId: '',
      },
      setCategoryList: (value) =>
        set(
          (state) => {
            state.categoryList = value;
          },
          false,
          'set-category-list'
        ),
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value.toString();
          },
          false,
          'setPage'
        ),
      setPageSize: (value: number) =>
        set(
          (state) => {
            state.params.pageSize = value.toString();
            state.params.page = '1';
          },
          false,
          'setPageSize'
        ),
    }))
  )
);
