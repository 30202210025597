import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
  useLocation,
} from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { msalInstance } from 'msal-init';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';

import Login from 'views/Login';
import { routes } from './routes';
import Profile from 'components/Profile/Profile';
import SideNav from './components/SideNav/SideNav';
import { Application, Button, MenuBar, USSLogo } from '@uss/react-components';

import { queryClient } from 'services/api/react-query';
import useUserRoles from 'hooks/useUserRoles';
import { intersection } from 'lodash';
import { NotificationContainer } from 'components/Notification/NotificationContainer';
import Home from 'views/home/Home';
import useWindowSize from 'hooks/useWindowSize';
import { useIdleTimer } from 'react-idle-timer';
import ModalProvider from 'components/Modals/ModalsContext';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import clsx from 'clsx';
import { trackPage } from 'utils/trackEvent';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/Error/Error';
import { useLoggedInUser, usePersonDetails } from 'features/people/api';

// Function to hand logins
const azureLogin = async () => {
  try {
    await msalInstance.loginRedirect();
  } catch (err) {
    // handle error
  }
};

function App() {
  const isAuthenticated = useIsAuthenticated();

  async function handleOnIdle(event: Event) {
    if (isAuthenticated) {
      await msalInstance.logoutRedirect({
        onRedirectNavigate: (url) => {
          // Return false if you would like to stop navigation after local logout
          return false;
        },
      });
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Router basename="/wms/app">
        <PageTracker />

        <ModalProvider>
          <ApplicationWrapper />
        </ModalProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

function AuthenticatedRoutes() {
  const { roles } = useUserRoles();
  useLoggedInUser();
  const protectedRoutes = routes.filter(({ permissions }) => {
    if (!permissions) return true;
    else return intersection(permissions, roles).length;
  });

  const element = useRoutes(protectedRoutes);
  return element;
}

function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path="login" element={<Login action={azureLogin} />} />
      <Route path="/*" element={<Home />} />
    </Routes>
  );
}

function ApplicationWrapper() {
  const { data: user, status: userLoading } = usePersonDetails('me');
  const { width } = useWindowSize();
  const isAuthenticated = useIsAuthenticated();
  const [qa, setQa] = useState(true);
  const [menuOpen, setMenuOpen] = useState(
    width && width > 768 && isAuthenticated && user && userLoading === 'success'
      ? true
      : false
  );

  React.useEffect(() => {
    if (isAuthenticated && user && userLoading === 'success') {
      setMenuOpen(true);
    }
  }, [isAuthenticated, user, userLoading]);

  let mobTabPortrait = false;
  if (width && width < 1024) {
    mobTabPortrait = true;
  }

  const hostname = window.location.hostname;

  const getAlert = (host: string) => {
    switch (host) {
      case 'cappsd.psc.uss.com':
        return {
          title: 'DEV',
          active: true,
        };
      case 'cappsu.psc.uss.com':
        return {
          title: 'UAT',
          active: true,
        };
      case 'localhost':
      case 'cappsq.psc.uss.com':
        return {
          title: 'QA',
          active: true,
        };
      default:
        return {
          title: '',
          active: false,
        };
    }
  };

  const getTitle = (host: string): { title: string; classes: string } => {
    switch (host) {
      case 'cappsd.psc.uss.com':
        return {
          title: 'Workforce Management System DEV',
          classes: 'text-red-400 lg:text-red-400',
        };
      case 'localhost':
      case 'cappsq.psc.uss.com':
        return {
          title: 'Workforce Management System QA',
          classes: 'text-red-400 lg:text-red-400',
        };
      case 'cappsu.psc.uss.com':
        return {
          title: 'Workforce Management System UAT',
          classes: 'text-red-400 lg:text-red-400',
        };
      default:
        return {
          title: 'Workforce Management System',
          classes: 'text-white lg:text-primary-400',
        };
    }
  };
  return (
    <>
      <Application
        isOpen={menuOpen}
        setOpen={setMenuOpen}
        className="bg-gray-100 overflow-y-scroll"
      >
        <Application.Header>
          <MenuBar
            variant={mobTabPortrait ? 'dark' : 'light'}
            onNavClick={() => setMenuOpen(!menuOpen)}
          >
            <div className="flex justify-between w-full items-center">
              <div className="flex flex-row items-center text-2xl cursor-pointer">
                <USSLogo
                  variant={mobTabPortrait ? 'white' : 'primary'}
                  width="50px"
                  height="50px"
                />
                <span
                  data-testid="menubar-title"
                  className={clsx(
                    'ml-2 font-bold text-xl sm:text-2xl',
                    getTitle(hostname).classes
                  )}
                >
                  {getTitle(hostname).title}
                </span>
              </div>
              <AuthenticatedTemplate>
                <div className="hidden lg:block">
                  <Profile />
                </div>
              </AuthenticatedTemplate>
            </div>
          </MenuBar>
        </Application.Header>

        <Application.Sidebar className="bg-primary-400 py-4">
          <SideNav setMenuOpen={setMenuOpen} user={user}></SideNav>
        </Application.Sidebar>
        <Application.Content>
          <main>
            <ErrorBoundary
              fallbackRender={(error) => (
                <Error
                  title="Something seems to have went wrong"
                  description={error.error.message}
                />
              )}
            >
              <AuthenticatedTemplate>
                {qa && getAlert(hostname).active && (
                  <div className="bg-red-100 mx-6 px-4 py-2 mt-4 mb-0 rounded-md flex justify-between items-center text-red-700">
                    <div className="flex justify-start items-center">
                      <BsExclamationCircle className="mr-2" />
                      You are currently on {getAlert(hostname).title}.
                    </div>
                    <Button variant="icon" onClick={() => setQa(false)}>
                      <BsX />
                      <span className="sr-only">Close Alert</span>
                    </Button>
                  </div>
                )}

                <AuthenticatedRoutes />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <UnauthenticatedRoutes />
              </UnauthenticatedTemplate>
            </ErrorBoundary>
          </main>
        </Application.Content>

        <NotificationContainer />
      </Application>
    </>
  );
}
const PageTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    trackPage();
  }, [location]);

  return <></>;
};

export default App;
