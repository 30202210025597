import { useQuery } from '@tanstack/react-query';
import { tableList, variables } from './api';
import { CategoryCodeListParamsOptions, TableListParams } from '../types';

export const useTables = (options: TableListParams) => {
  return useQuery({
    queryKey: ['tables', options],
    queryFn: () => tableList.get(options),
    refetchOnMount: true,
  });
};

export const useVariables = (options: CategoryCodeListParamsOptions) => {
  return useQuery({
    queryKey: ['variables', options],
    queryFn: () => variables.get(options),
    refetchOnMount: true,
  });
};
