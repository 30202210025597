import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IncentiveListParams } from '../types/types';

interface IncentiveList {
  params: IncentiveListParams;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
}

export const useIncentiveListStore = create<IncentiveList>()(
  devtools(
    immer((set) => ({
      params: {
        page: '1',
        pageSize: '9',
      },
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value.toString();
          },
          false,
          'setPage'
        ),
      setPageSize: (value: number) =>
        set(
          (state) => {
            state.params.pageSize = value.toString();
            state.params.page = '1';
          },
          false,
          'setPageSize'
        ),
    }))
  )
);
