import Page from 'components/Layouts/Page';
import CardSkeleton from 'components/Skeleton/CardSkeleton';
import { useVariables } from 'features/payroll-table-maintenance/api/queries';
import { InactivateForm } from 'features/payroll-table-maintenance/components/InactivateForm';
import { useVariableStore } from 'features/payroll-table-maintenance/store/variable-store';

import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

export const CodeInactivateForm = () => {
  const { id = '' } = useParams<'id'>();
  const variableStore = useVariableStore();

  const { data: variableList, status } = useVariables({
    ...variableStore.params,
    varDefinitionId: id,
  });

  const pageHeading = `Inactivate ${
    variableList?.varDefinitionName ? variableList.varDefinitionName : ''
  }`;
  return (
    <>
      <Page
        heading={pageHeading}
        breadcrumbs={[
          <NavLink key="bc-1" to="/admin">
            Admin
          </NavLink>,
          <NavLink key="bc-1" to="/admin/payroll-table-maintenance">
            Payroll Table Maintainece
          </NavLink>,
          <NavLink
            key="bc-1"
            to={`/admin/payroll-table-maintenance/category/${id}`}
          >
            {variableList?.varDefinitionName}
          </NavLink>,
        ]}
      >
        <Page.Section>
          {status === 'pending' && (
            <div className=" grid md:grid-cols-3 gap-5  ">
              {Array.from(Array(9)).map((index: number) => (
                <CardSkeleton contentRows={1} variant="row-grid" key={index} />
              ))}
            </div>
          )}
          {status === 'success' && (
            <InactivateForm variableList={variableList} />
          )}
        </Page.Section>
      </Page>
    </>
  );
};
