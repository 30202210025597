import { axios } from 'services/api/axios';
import { IncentiveListParams, IncentiveListResponse } from '../types/types';

export const incentiveList = {
  get: (
    options?: IncentiveListParams
  ): Promise<IncentiveListResponse | undefined> => {
    return axios.get(`/v1/incentives`, {
      params: options,
    });
  },
};
