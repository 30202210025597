import { INCENTIVE_STATUS } from '../constants';

export const getIncentiveStatusText = (status: string) => {
  switch (status) {
    case INCENTIVE_STATUS.ACTIVE: {
      return 'Active';
    }
    case INCENTIVE_STATUS.INACTIVE: {
      return 'Inactive';
    }
    default:
      return '';
  }
};
