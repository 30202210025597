import { Link } from '@uss/react-components';
import Card from 'components/Card/Card';
import { NavLink } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import React, { useEffect } from 'react';

import { useVariableStore } from '../store/variable-store';

interface Props {
  category: string;
  id: string;
}

export const TableCategoryCard = ({ category, id }: Props) => {
  const variableStore = useVariableStore();

  useEffect(() => {
    variableStore.setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <Link
        as={NavLink}
        className="block cursor-pointer"
        key={id}
        to={`category/${id}`}
      >
        <div className="px-5 py-4 flex items-center text-brand">
          <div className="flex-grow text-lg">{category}</div>
          <BsChevronRight size={'1.5rem'} />
        </div>
      </Link>
    </Card>
  );
};
