import { Org } from 'features/org/types';
import { TimecardFeature } from '../types';
import { WeekStart } from 'types/common';
import {
  format,
  getDay,
  getHours,
  isBefore,
  isMonday,
  parse,
  startOfWeek,
} from 'date-fns';
import useCurrentDate from 'hooks/useCurrentDate';
import { utcToZonedTime } from 'date-fns-tz';
type PlantType = Pick<Org, 'plantType'>;
interface Props extends PlantType {
  feature: TimecardFeature;
  weekStartDate: WeekStart;
}
interface MiniMillProps {
  currentESTDate: Date;
  currentWeekStart: Date;
  previousWeekStart: Date;
}
interface IntegratedMillProps {
  currentWeekStart: Date;
  previousWeekStart: Date;
  nextWeekStart: Date;
  feature: TimecardFeature;
  weekStartDate: WeekStart;
}
interface OfficeProps {
  previousWeekStart: Date;
  nextWeekStart: Date;
  feature: TimecardFeature;
  weekStartDate: WeekStart;
}
const getMiniMillDefaultWeek = ({
  currentESTDate,
  currentWeekStart,
  previousWeekStart,
}: MiniMillProps) => {
  /**
   * Boolean to determine if the current week is open or not.  Evaluates true if it is past Monday 12pm
   */
  const currentWeekOpen =
    (isMonday(currentESTDate) && !(getHours(currentESTDate) < 12)) ||
    (getDay(currentESTDate) > 1 && getDay(currentESTDate) < 6);
  /**
   * The current open pay period.  Pay periods closed at 12pm EST/EDT on Mondays.
   *
   * @example An example of this would be to determine the default week that should display when the page initially loads.
   */
  const currentOpenWeek = currentWeekOpen
    ? currentWeekStart
    : previousWeekStart;
  return format(currentOpenWeek, 'yyyy-MM-dd');
};

const getIntegratedMillDefaultWeek = ({
  feature,
  previousWeekStart,
  nextWeekStart,
  weekStartDate,
}: IntegratedMillProps) => {
  // NU Integrated and Office roll off date
  const integratedOfficeCutOffDate = startOfWeek(
    parse('2025-02-10', 'yyyy-MM-dd', new Date()),
    {
      weekStartsOn: weekStartDate,
    }
  );

  if (feature === 'pay')
    return format(
      isBefore(previousWeekStart, integratedOfficeCutOffDate)
        ? integratedOfficeCutOffDate
        : previousWeekStart,
      'yyyy-MM-dd'
    );
  else
    return format(
      isBefore(nextWeekStart, integratedOfficeCutOffDate)
        ? integratedOfficeCutOffDate
        : nextWeekStart,
      'yyyy-MM-dd'
    );
};
const getOfficeDefaultWeek = ({
  feature,
  previousWeekStart,
  nextWeekStart,
  weekStartDate,
}: OfficeProps) => {
  // NU Integrated and Office roll off date
  const integratedOfficeCutOffDate = startOfWeek(
    parse('2025-02-10', 'yyyy-MM-dd', new Date()),
    {
      weekStartsOn: weekStartDate,
    }
  );

  if (feature === 'pay')
    return format(
      isBefore(previousWeekStart, integratedOfficeCutOffDate)
        ? integratedOfficeCutOffDate
        : previousWeekStart,
      'yyyy-MM-dd'
    );
  else
    return format(
      isBefore(nextWeekStart, integratedOfficeCutOffDate)
        ? integratedOfficeCutOffDate
        : nextWeekStart,
      'yyyy-MM-dd'
    );
};

function useDefaultWeek({ plantType, feature, weekStartDate }: Props) {
  const currentESTDate = utcToZonedTime(new Date(), 'America/New_York');
  const { currentWeekStart, previousWeekStart, nextWeekStart } = useCurrentDate(
    {
      weekStart: weekStartDate,
    }
  );

  switch (plantType) {
    case 'mini_mill':
      return getMiniMillDefaultWeek({
        currentESTDate,
        currentWeekStart,
        previousWeekStart,
      });

    case 'integrated_mill':
      return getIntegratedMillDefaultWeek({
        feature,
        currentWeekStart,
        previousWeekStart,
        nextWeekStart,
        weekStartDate,
      });
    case 'office':
      return getOfficeDefaultWeek({
        feature,
        previousWeekStart,
        nextWeekStart,
        weekStartDate,
      });
    default:
      return format(currentWeekStart, 'yyyy-MM-dd');
  }
}

export default useDefaultWeek;
