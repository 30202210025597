import { SearchInput } from '@uss/react-components';
import Page from 'components/Layouts/Page';
import Sort from 'components/Sort/Sort';
import { useEmployeeOrgs } from 'features/org/api';
import { PAY_PERIOD_STATUS } from 'features/pay-periods/types';
import AddTempEmployee from 'features/timecards/components/CrewListing/AddTempEmployee';
import CrewTimecards from 'features/timecards/components/CrewListing/CrewTimecardList';
import { TimecardStatusSelect } from 'features/timecards/components/Filters/TimecardStatusSelect';
import WeekSelect from 'features/timecards/components/Filters/WeekSelect';
import { useCrewTimecards } from 'features/timecards/api/crewTimecard';
import { usePrimaryPayApprover } from 'services/api/common';
import { SortOptions } from 'features/timecards/types';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import Pagination from 'components/Pagination/Pagination';
import { getCrewTimecardsSortOptions } from 'features/timecards/utilities/getCrewTimecardsSortOptions';
import CrewDetails from 'features/timecards/components/CrewListing/CrewDetails';
import { ORG_TYPE } from 'features/org';
import useUserRoles from 'hooks/useUserRoles';
import { SortDirection } from 'types/common';
import { NavLink } from 'react-router-dom';
import useOrgFilters from 'components/OrgFilters/use-org-filters';
import OrgFilters from 'components/OrgFilters/OrgFilters';
import { useEffect } from 'react';
import FilterLoader from 'features/timecards/components/CrewListing/Loaders/FilterLoader';
import { EmployeeClassSelect } from 'features/timecards/components/Filters/EmployeeClassSelect';

const SchedulingCrewsListing = () => {
  const breadCrumbNodes = [
    <NavLink key="bc-1" to="/scheduling">
      Scheduling
    </NavLink>,
  ];
  const state = useNonUnionStore();

  const { isSuperAdmin } = useUserRoles();
  const role = isSuperAdmin
    ? 'super_admin,super_payroll_admin'
    : 'pay_approver,scheduling_admin,schedule_pay_viewer,t_a_plant_coordinator,payroll_admin';

  const { data: plantList, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    sortType: SortDirection.ASC,
    role: role,
    status: 'active',
    context: 'summary',
  });

  const orgs = useEmployeeOrgs('me', {
    sortType: SortDirection.ASC,
    sortBy: 'code',
    parentOrgId: state.selectedPlant?.id ?? undefined,
    role: role,
    status: 'active',
  });

  const { payrollLocations, crews } = useOrgFilters({
    orgs: orgs.data?.items ? orgs.data.items : [],
    selectedPlant: state.selectedPlant,
    selectedPayrollLocation: state.selectedLocation,
    crewSort: 'code',
  });
  const plantType = plantList?.items.find(
    (plant) => state.selectedPlant && plant.id === state.selectedPlant.id
  )?.plantType;
  // Crew Select

  //Fetch Crew Timecards
  const { data: timecards, isLoading } = useCrewTimecards(
    state.crew?.id ? state.crew.id : '',
    {
      week: state.params.week ?? '',
      page: state.params.page,
      pageSize: state.params.pageSize,
      sortBy: state.params.sortBy,
      sortType: state.params.sortType,
      search: state.params.search,
      employeeType: state.params.employeeType,
      status: state.params.status,
      empClassFilter: state.params.empClassFilter,
    },
    crews
  );
  //Display loader initially until crews are loaded
  let shouldDisplayLoader = true;
  if (orgs.status === 'success') {
    //Setting shouldDisplayLoader to false if user does not have access to the crew selected
    shouldDisplayLoader =
      !!state.crew?.id && !!crews.find((crew) => crew.id === state.crew?.id);
  }
  const isLoadingTimecards = isLoading && shouldDisplayLoader;
  // Fetch Primary Approver
  const { data: payApprover } = usePrimaryPayApprover(
    state.crew?.id ? state.crew.id : ''
  );
  const sortOptions = getCrewTimecardsSortOptions();

  if (
    plantStatus === 'success' &&
    plantList.items.length > 0 &&
    !!state.selectedPlant &&
    !Number.isInteger(state.selectedPlant.weekBeginDayIndex)
  ) {
    throw new Error("Plant's weekBeginDayIndex cannot be null/undefined");
  }
  useEffect(() => {
    state.setFeature('scheduling');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      plantStatus === 'success' &&
      plantList.items.length > 0 &&
      !plantList.items.find((c) => !!c.id && c.id === state.selectedPlant?.id)
    ) {
      state.setPlant(plantList.items[0]);
    }
    if (orgs.status === 'success') {
      if (
        payrollLocations.length > 0 &&
        state.selectedLocation !== 'all' &&
        !payrollLocations.find(
          (c) =>
            state.selectedLocation !== 'all' &&
            !!c.id &&
            c.id === state.selectedLocation?.id
        )
      ) {
        state.setPayrollLocation('all');
      }
      if (
        crews.length > 0 &&
        !crews.find((c) => !!c.id && c.id === state.crew?.id)
      ) {
        state.setCrew(crews[0]);
      }
    }
  });

  return (
    <Page breadcrumbs={breadCrumbNodes} heading={'Schedules (NU)'}>
      <Page.Section bg="gray">
        <div className="flex flex-row justify-between flex-wrap">
          <div className="w-full sm:w-10/12">
            <div className="w-full mb-2 sm:mb-0 sm:w-4/12 ">
              <SearchInput
                label="Search"
                id="search"
                placeholder="Search Name / USSID"
                value={state.params.search ?? ''}
                autoCompleteFlag={state.params.search ? true : false}
                selectedFlag={state.params.search ? true : false}
                onChange={(e) => {
                  state.setSearch(e.currentTarget.value);
                }}
                onClose={() => {
                  state.clearSearch();
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-end w-full sm:w-1/6">
            <Sort<SortOptions>
              options={sortOptions}
              onChange={(value) =>
                state.setSort({
                  type: value.name,
                  direction: value.direction,
                })
              }
              selectedOption={
                sortOptions.filter(
                  (value) =>
                    state.params.sortBy === value.name &&
                    state.params.sortType === value.direction
                )[0]
              }
            />
          </div>
        </div>
      </Page.Section>
      <Page.Section bg="gray">
        <>
          {plantStatus === 'success' && orgs.status === 'success' ? (
            <>
              <div className="flex flex-col gap-y-2 sm:gap-y-3">
                <OrgFilters
                  plants={plantList.items}
                  orgs={orgs.data.items}
                  selectedOrgs={{
                    selectedPlant: state.selectedPlant,
                    selectedPayrollLocation: state.selectedLocation,
                  }}
                  status={orgs.status}
                  plantStatus={plantStatus}
                  crewSort="code"
                >
                  <OrgFilters.Plant
                    selectedItem={state.selectedPlant}
                    handleSelect={state.setPlant}
                  />
                  <OrgFilters.Location
                    selectedItem={state.selectedLocation}
                    handleSelect={state.setPayrollLocation}
                    showSelectAll={true}
                  />
                  <OrgFilters.Crew
                    selectedItem={state.crew}
                    handleSelect={state.setCrew}
                    showSelectAll={false}
                    displayCrewKey={true}
                  />
                  <WeekSelect
                    startDayOfWeek={state.selectedPlant?.weekBeginDayIndex}
                    plantType={state.selectedPlant?.plantType}
                  />{' '}
                  <EmployeeClassSelect
                    selectedEmpClass={state.params.empClassFilter}
                    setEmpClass={state.setEmpClass}
                    plantType={plantType}
                  />
                  <TimecardStatusSelect
                    selectedStatus={state.params.status}
                    setStatus={state.setStatus}
                  />
                </OrgFilters>
              </div>
              <div className="flex sm:rounded-none sm:border-0 sm:justify-end w-full my-4">
                {((state.selectedPlant?.plantType === 'mini_mill' &&
                  (timecards?.payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
                    timecards?.payPeriodStatus ===
                      PAY_PERIOD_STATUS.NOT_OPEN)) ||
                  state.selectedPlant?.plantType === 'integrated_mill' ||
                  state.selectedPlant?.plantType === 'office') && (
                  <AddTempEmployee />
                )}
              </div>
            </>
          ) : (
            <FilterLoader count={6} />
          )}
          <div className="p-4 bg-white rounded-md">
            <div className="mb-4">
              <CrewDetails
                crewKey={timecards?.crewKey}
                crewDescription={timecards?.crewDescription}
                totalCount={timecards?.totalCount}
                payPeriodStatus={timecards?.payPeriodStatus}
                primaryApprover={payApprover?.displayName}
                plantType={plantType}
              />
            </div>

            <div className="mb-4 ">
              <CrewTimecards
                isLoadingTimecards={isLoadingTimecards}
                employees={timecards?.items}
                payPeriodStatus={timecards?.payPeriodStatus}
                plantType={plantType}
              />
            </div>
          </div>
        </>
      </Page.Section>
      <Page.Section bg="gray">
        {timecards && (
          <Pagination
            pageOptions={[5, 10, 15, 20, 25, 30]}
            totalCount={timecards.totalCount}
            page={Number(state.params.page)}
            pageSize={Number(state.params.pageSize)}
            onPageChange={(s) => state.setPage((s.selected + 1).toString())}
            onPageSizeChange={(ps) => state.setPageSize(ps.toString())}
          />
        )}
      </Page.Section>
    </Page>
  );
};

export default SchedulingCrewsListing;
