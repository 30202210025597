import { z } from 'zod';

export const AddVariableSchema = z.object({
  code: z.string().nonempty({ message: 'Code is required' }),
  value: z.string().nonempty({ message: 'Value is required' }),
  varDefinitionId: z
    .string()
    .nonempty({ message: 'varDefinitionId is required' }),
});
export const AddVariableFormSchema = z.object({
  code: z.string().nonempty({ message: 'Code is required' }),
  value: z.string().nonempty({ message: 'Value is required' }),
});
export const VariableListSchema = z.object({
  id: z.string(),
  varDefinitionName: z.string(),
  varDefinitionDisplayName: z.string(),
  codeType: z.string(),

  codeLength: z.number(),
  valueType: z.string(),

  valueLength: z.number(),
  codeRequired: z.string(),
  valueRequired: z.string(),
});

export const AddVariableResponseSchema = z.object({
  items: z.array(VariableListSchema),

  page: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
});
