import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
  BsCalendar2Range,
  BsFiles,
  BsInfoCircle,
  BsListNested,
} from 'react-icons/bs';
import { NavLink, useParams } from 'react-router-dom';
import { updateTimecard } from 'features/timecards/utilities/updateTimecard';
import {
  AddAdjustmentPayload,
  EmployeeTimecard,
  EmployeeTimecardList,
  TimecardPayload,
  TimecardTotal,
  TIMECARD_UPDATE_ACTIONS,
  TimecardUpdatePayload,
} from 'features/timecards/types';
import { HolidayList } from 'features/holidays';
import TableHeader from './TimecardTable/TableHeader';
import { Button, Table } from '@uss/react-components';
import TableRow from './TimecardTable/TableRow';
import TimecardActionsContainer from './TimecardTable/Actions/TimecardActionsContainer';
import TableTotalHoursRow from './TimecardTable/TableTotalHoursRow';
import { sum } from 'lodash';
import { Person, ShiftAssignment } from 'features/people/types';
import checkDateHoliday from 'features/timecards/utilities/checkDateHoliday';
import TimecardCard from './TimecardCard/TimecardCard';
import TimecardTotalHrsCard from './TimecardCard/TimecardTotalHrsCard';
import TimecardApprovalDetails from './Details/TimecardApprovalDetails';
import { WMSError } from 'types/common';
import useModal from 'components/Modals/use-modal';
import { PAY_PERIOD_STATUS } from 'features/pay-periods';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import useUserRoles from 'hooks/useUserRoles';
import { UseMutationResult } from '@tanstack/react-query';
import { useBlackoutPto } from 'features/paid-time-off/api/queries';
import ApplyModelDrawer from '../../../model-listing/components/ApplyModel/ApplyModelDrawer';

interface TimecardProps {
  employeeTimecardObj: EmployeeTimecardList;
  isTimecardDisabled: boolean;
  holidayList: HolidayList | undefined;
  shiftAssignment: ShiftAssignment;
  createTimecard: UseMutationResult<
    EmployeeTimecard,
    WMSError,
    TimecardPayload[]
  >;
  updateEmployeeTimecard: UseMutationResult<
    EmployeeTimecard,
    WMSError,
    TimecardUpdatePayload
  >;
  createAdjustment: UseMutationResult<
    EmployeeTimecard,
    WMSError,
    AddAdjustmentPayload
  >;
  width: number;
  employee: Person;
}
const Timecard = ({
  employeeTimecardObj,
  isTimecardDisabled,
  holidayList,
  shiftAssignment,
  createTimecard,
  updateEmployeeTimecard,
  createAdjustment,
  width,
  employee,
}: TimecardProps) => {
  const { roles } = useUserRoles();
  const { ussId: id = '' } = useParams<'ussId'>();

  const [timecardRowData, dispatch] = useReducer(
    updateTimecard,
    employeeTimecardObj.items[0].timecard
  );

  const timecard = employeeTimecardObj.items[0];
  const { data: blackoutData } = useBlackoutPto(id);

  const timecardHoursTotals: TimecardTotal = useMemo(() => {
    return {
      scheduled: sum(timecardRowData.map((t) => t.scheduled.hours)),
      worked: sum(timecardRowData.map((t) => t.worked.hours)),
      approved: sum(timecardRowData.map((t) => t.approved.hours)),
      scheduleVariance: sum(
        timecardRowData.map((t) => t.scheduleVariance.variance)
      ),
      paidHoursNotWorked: sum(timecardRowData.map((t) => t.paidHoursNotWorked)),
    };
  }, [timecardRowData]);

  useEffect(() => {
    if (employeeTimecardObj.items.length) {
      dispatch({
        type: TIMECARD_UPDATE_ACTIONS.RESET_INITIAL_DATA,
        payload: employeeTimecardObj.items[0].timecard,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    employeeTimecardObj,
    timecard.week,
    timecard.adjustment,
    timecard.crewId,
  ]);

  const modal = useModal();

  const clearAllTimecardFields = () => {
    for (let i = 0; i < employeeTimecardObj.items[0].timecard.length; i++) {
      dispatch({
        type: TIMECARD_UPDATE_ACTIONS.CLEAR_SHIFT,
        payload: {
          rowIndex: i,
          columnName: 'scheduledHours',
          scheduleVariance: 0,
        },
      });
    }

    modal.closeModal();
  };

  const onClearAll = () => {
    modal.openModal({
      title: 'Clear All?',
      type: 'regular',
      children: (
        <p className="text-sm text-gray-700">
          Are you sure you want to clear the fields for this timecard?
        </p>
      ),
      labels: { confirm: 'Clear', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => clearAllTimecardFields(),
    });
  };

  return (
    <>
      <div className="p-4 bg-white rounded-md">
        <TimecardDescription
          employeeTimecard={employeeTimecardObj}
          onClearAll={onClearAll}
          plantType={employee.plantType}
          shiftAssignment={shiftAssignment}
        />
        {width > 786 ? (
          <Table>
            <TableHeader />
            <Table.Body>
              {timecardRowData.map((data, i) => {
                return (
                  <TableRow
                    timecardInstance={timecardRowData}
                    data={data}
                    employeeTimecardObj={employeeTimecardObj}
                    updateTimecard={dispatch}
                    rowIndex={i}
                    key={i.toString() + '-row'}
                    isTimecardDisabled={isTimecardDisabled}
                    shiftAssignment={shiftAssignment}
                    isHoliday={checkDateHoliday(data.date, holidayList)}
                    approvedStatus={data.ptoDetails?.status === 'approved'}
                    requestedStatus={data.ptoDetails?.status === 'requested'}
                    isBlackout={blackoutData?.items.some(
                      (obj) => obj.startDate === data.date
                    )}
                    employee={employee}
                    roles={roles}
                  />
                );
              })}
            </Table.Body>
            <Table.Footer>
              <TableTotalHoursRow totals={timecardHoursTotals} />
            </Table.Footer>
          </Table>
        ) : (
          <>
            <hr className="-ml-2 -mr-2 border" />

            {timecardRowData.map((data, i) => {
              return (
                <TimecardCard
                  data={data}
                  timecardInstance={timecardRowData}
                  employeeTimecardObj={employeeTimecardObj}
                  updateTimecard={dispatch}
                  rowIndex={i}
                  key={i.toString() + '-row'}
                  isTimecardDisabled={isTimecardDisabled}
                  isHoliday={checkDateHoliday(data.date, holidayList)}
                  approvedStatus={data.ptoDetails?.status === 'approved'}
                  requestedStatus={data.ptoDetails?.status === 'requested'}
                  isBlackout={blackoutData?.items.some(
                    (obj) => obj.startDate === data.date
                  )}
                  shiftAssignment={shiftAssignment}
                  employee={employee}
                  roles={roles}
                />
              );
            })}
            <TimecardTotalHrsCard totals={timecardHoursTotals} />
          </>
        )}
        <div className="mt-4">
          <TimecardApprovalDetails
            approvalDetails={employeeTimecardObj.items[0]}
          />
        </div>
      </div>
      <TimecardActionsContainer
        employeeTimecardObj={employeeTimecardObj.items[0]}
        timecardInstance={timecardRowData}
        holidayList={holidayList}
        isTimecardDisabled={isTimecardDisabled}
        createTimecard={createTimecard}
        updateEmployeeTimecard={updateEmployeeTimecard}
        createAdjustment={createAdjustment}
        shiftAssignment={shiftAssignment}
        totalHrs={timecardHoursTotals}
        employee={employee}
      />
    </>
  );
};

const TimecardDescription = ({
  employeeTimecard,
  onClearAll,
  plantType,
  shiftAssignment,
}: {
  employeeTimecard: EmployeeTimecardList;
  onClearAll: () => void;
  plantType?: string | null;
  shiftAssignment?: string | null;
}) => {
  const state = useNonUnionStore();
  //create-model-drawer
  const [openApplyModelDrawer, setOpenApplyModelDrawer] = useState(false);

  const feature = state.feature;
  const isPay = feature === 'pay';
  const isMySchedule = feature === 'my-schedule';
  const isSchedulingDetails = feature === 'scheduling';
  const { ussId = '' } = useParams<'ussId'>();

  const isFutureWeek =
    employeeTimecard.items[0].payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN;
  //show Apply-Model button for my-schedule screen only
  const showApplyModel = isMySchedule || isSchedulingDetails;

  return (
    <div
      className={`flex ${
        isFutureWeek ? 'flex-col' : 'flex-row'
      } sm:flex-row justify-between `}
      data-testid="timecard-description"
    >
      <div className={`flex ${isFutureWeek ? 'pt-0' : 'pt-2'} sm:pt-2`}>
        <BsInfoCircle className="text-blue-500" />
        <span className="ml-2 text-sm text-gray-700">
          {employeeTimecard.items[0].status.description}
        </span>
      </div>
      <div
        className={`flex items-center ${
          isFutureWeek ? 'mt-4' : 'mt-0'
        } sm:mt-0 justify-around`}
      >
        {!isPay &&
          employeeTimecard.items[0].id !== 'temp' &&
          (plantType === 'integrated_mill' ||
            plantType === 'mini_mill' ||
            plantType === 'office') && (
            <div className="flex items-center text-sm p-2">
              <BsFiles className="text-primary-400" />
              <NavLink
                className={
                  'text-primary-400 font-semibold cursor-pointer ml-2 text-sm'
                }
                to={
                  isMySchedule
                    ? `/my-schedule-nu/${ussId}/copy-schedule`
                    : `/non-union-scheduling/employee/${ussId}/copy-schedule`
                }
                state={employeeTimecard.items[0].id}
              >
                Copy
              </NavLink>
            </div>
          )}
        {(isSchedulingDetails || isMySchedule) &&
          employeeTimecard.items[0].id !== 'temp' &&
          isFutureWeek && (
            <div className="flex items-center gap-1 ml-4 mr-4 p-2">
              <Button
                variant="icon"
                className="cursor-pointer flex items-center justify-center text-sm"
                onClick={onClearAll}
                startIcon={
                  <BsListNested size="1rem" className="text-primary-400" />
                }
              >
                <span className="font-semibold text-primary-400 text-sm">
                  Clear All
                </span>
              </Button>
            </div>
          )}
        {isFutureWeek && showApplyModel && (
          <Button
            variant="text"
            onClick={() => setOpenApplyModelDrawer(true)}
            className={
              'text-primary-400 font-semibold cursor-pointer ml-4 text-sm'
            }
          >
            <BsCalendar2Range className="text-primary-400" />
            <span className="ml-2">Apply Model</span>
          </Button>
        )}
      </div>
      <ApplyModelDrawer
        applyFeature="timecard"
        openDrawer={openApplyModelDrawer}
        setOpenDrawer={setOpenApplyModelDrawer}
      />
    </div>
  );
};

export { Timecard };
