import { OrgList } from 'features/org';
import { Plant } from '../types';

export const getPlantList = (plantList: OrgList, drawerType?: string) => {
  const plantArr: Plant[] = [];
  if (plantList.items.length > 0) {
    if (drawerType === 'transferOwner') {
      const allOption: Plant = {
        id: 'all',
        plant: 'All',
        weekBeginDayIndex: 0,
      };
      plantArr.push(allOption);
    }
    plantList.items.forEach((data) => {
      const obj: Plant = {
        id: data.id,
        plant: data.plant,
        weekBeginDayIndex: data.weekBeginDayIndex,
      };
      plantArr.push(obj);
    });
  }
  return plantArr;
};
