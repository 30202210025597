import CardList from 'components/Card/CardList';
import Page from 'components/Layouts/Page';
import { NavLink } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import { useTableCategoryListStore } from 'features/payroll-table-maintenance/state/table-category-list-store';
import { TableList } from 'features/payroll-table-maintenance/types';
import { useTables } from 'features/payroll-table-maintenance/api/queries';
import { TableCategoryCard } from 'features/payroll-table-maintenance/components/TableCategoryCard';
import CardSkeleton from 'components/Skeleton/CardSkeleton';

const TableMaintenance = () => {
  const store = useTableCategoryListStore();
  const { data: tableList, status: tableStatus } = useTables({
    ...store.params,
  });

  return (
    <Page
      heading="Payroll Table Maintenance"
      breadcrumbs={[
        <NavLink key="bc-1" to="/admin">
          Admin
        </NavLink>,
      ]}
    >
      <Page.Section className="flex flex-col gap-4">
        {tableStatus === 'pending' && (
          <div className=" grid grid-cols-3 gap-5  ">
            {Array.from(Array(9)).map((index: number) => (
              <CardSkeleton contentRows={1} variant="row-grid" key={index} />
            ))}
          </div>
        )}
        {tableStatus === 'success' && (
          <>
            <div className="flex-grow">
              <CardList<TableList>
                items={tableList.items}
                renderItem={(category) => (
                  <TableCategoryCard
                    category={category.varDefinitionDisplayName}
                    id={category.id}
                  />
                )}
              />
            </div>
            <div className="justify-end">
              <Pagination
                pageOptions={[9, 18, 27, 36, 45, 54]}
                totalCount={tableList.totalCount}
                page={Number(store.params.page)}
                pageSize={Number(store.params.pageSize)}
                onPageChange={(s) => store.setPage(s.selected + 1)}
                onPageSizeChange={(ps) => store.setPageSize(ps)}
              />
            </div>
          </>
        )}
      </Page.Section>
    </Page>
  );
};

export default TableMaintenance;
