import {
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  UnstyledButton,
} from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { useUpdateVariable } from 'features/payroll-table-maintenance/api/mutations';
import {
  CategoryCodeListResponse,
  UpdateVariablePayload,
} from 'features/payroll-table-maintenance/types';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsBagX, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { queryClient } from 'services/api/react-query';

interface InactivateFormProps {
  variableList: CategoryCodeListResponse;
}

export const InactivateForm = ({ variableList }: InactivateFormProps) => {
  const navigate = useNavigate();
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState<UpdateVariablePayload | null>(null);
  const navigateToPrevPage = () => {
    navigate(
      `/admin/payroll-table-maintenance/category/${variableList.varDefinitionId}`
    );
  };

  const { control, handleSubmit } = useForm<UpdateVariablePayload>({
    defaultValues: {
      items: variableList.items,
    },
  });
  const { mutateAsync: updateVariableMutate } = useUpdateVariable(
    variableList.varDefinitionId
  );

  const pageHeading = `Inactivate ${
    variableList.varDefinitionName ? variableList.varDefinitionName : ''
  }`;
  const handleOnChange = (val: string[]) => {
    if (val.filter((e) => e !== 'all').length === variableList.items.length) {
      setIsSelectedAll(true);
      setChecked(['all', ...val]);
    } else {
      setIsSelectedAll(false);
      setChecked(val.filter((e) => e !== 'all'));
    }
  };
  const onSelectAllHandle = (val: boolean) => {
    if (val) {
      setChecked(['all', ...variableList.items.map((e) => e.id)]);
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
      setChecked([]);
    }
  };
  const onSubmit = (data: UpdateVariablePayload) => {
    setIsOpen(true);

    const inactivatedCode = {
      items: data.items
        .filter((e) => checked.includes(e.id))
        .map((e) => {
          return {
            ...e,
            isActive: 'N',
          };
        }),
    };

    setPayload(inactivatedCode);
  };
  const handleInactiveSubmit = async () => {
    payload &&
      (await updateVariableMutate(payload, {
        onSuccess: async () => {
          navigateToPrevPage();
          notify.success({
            message: `You have successfully inactivated ${
              variableList.varDefinitionName
                ? variableList.varDefinitionName
                : ''
            } record.`,
          });
          await queryClient.resetQueries({
            queryKey: ['variables'],
            type: 'active',
          });
        },
      }));
  };
  return (
    <>
      <form
        className="flex flex-col flex-grow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CheckboxGroup
          orientation="vertical"
          label=""
          aria-label="Select All"
          value={checked}
          onChange={(e) => handleOnChange(e)}
        >
          <Checkbox
            isIndeterminate={isSelectedAll}
            isSelected={isSelectedAll}
            value="all"
            onChange={(e) => onSelectAllHandle(e)}
          >
            Select All
          </Checkbox>
          <div className=" grid md:grid-cols-3 x gap-5 pb-10 pt-2 ">
            {variableList.items.map((fieldItem, index) => {
              return (
                <>
                  <div className="rounded-lg bg-white py-3 px-4 flex gap-1 flex-nowrap items-center ">
                    <Controller
                      control={control}
                      name={`items.${index}.isActive`}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          key={field.name}
                          value={fieldItem.id}
                        >
                          <span className="text-lg font-semibold">
                            {fieldItem.code}
                          </span>

                          <span className=" text-sm pl-2">
                            {fieldItem.value}
                          </span>
                        </Checkbox>
                      )}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </CheckboxGroup>

        {(isSelectedAll || checked.length > 0) && (
          <div className="flex flex-wrap p-2 bg-primary-300  text-white h-auto sm:h-14 items-center rounded-sm text-base">
            <p className="text-center sm:text-left w-full sm:w-1/5">
              {checked.filter((e) => e !== 'all').length}/
              {variableList.items.length} Records selected
            </p>
            <div className="flex flex-wrap justify-end w-full sm:w-4/5 gap-3 pr-4">
              <Button
                variant="text"
                onPress={navigateToPrevPage}
                className=" text-white"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="text"
                className="flex gap-1 p-2 items-center text-white"
              >
                <span className="w-6 h-6 flex items-center">
                  <BsBagX size="1.25rem" />
                </span>
                Inactivate
              </Button>
            </div>
          </div>
        )}

        <Modal ariaLabel="" size="default" isOpen={isOpen}>
          <Modal.Header>
            <div className="flex flex-grow items-center ">
              <h2>{pageHeading}</h2>
            </div>
            <UnstyledButton>
              <BsX
                size="2rem"
                onClick={() => setIsOpen(false)}
                className="cursor-pointer absolute sm:relative right-2 sm:right-0"
              />
            </UnstyledButton>
          </Modal.Header>
          <Modal.Content>
            <p className="text-sm text-gray pb-8">
              {`Are you sure you want to ${pageHeading} record?`}
            </p>
          </Modal.Content>
          <Modal.Footer>
            <div className="sm:flex sm:justify-between sm:gap-2">
              <Button
                variant="outline"
                color={'primary'}
                full={false}
                type="button"
                onPressChange={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color={'primary'}
                full={false}
                type="button"
                onPress={handleInactiveSubmit}
              >
                Inactivate
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};
