import { useQuery } from '@tanstack/react-query';
import { IncentiveListParams } from '../types/types';
import { incentiveList } from './api';

export const useIncentiveList = (options?: IncentiveListParams) => {
  return useQuery({
    queryFn: () => incentiveList.get(options),
    queryKey: ['incentive-list', { ...options }],
  });
};
