import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  IconButton,
  Label,
  Sidepane,
  TextField,
} from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { BsX } from 'react-icons/bs';
import { queryClient } from 'services/api/react-query';
import { useAddVariable } from '../api/mutations';
import { AddVariableFormSchema } from '../Schemas/add-code';
import { AddVariableForm } from '../types';

interface AddCodeDrawerProps {
  isOpenDrawer: boolean;
  setIsAddDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  varDefinitionId: string;
  category?: string;
}
export const AddCodeDrawer = ({
  isOpenDrawer,
  setIsAddDrawerOpen,
  varDefinitionId,
  category,
}: AddCodeDrawerProps) => {
  const { control, handleSubmit } = useForm<AddVariableForm>({
    mode: 'onSubmit',
    resolver: zodResolver(AddVariableFormSchema),
    defaultValues: {},
  });

  const { mutateAsync: addVariableMutateAsync } = useAddVariable();
  const code = useWatch({ control, name: 'code' });
  const value = useWatch({ control, name: 'value' });
  const handleChange = async () => {
    const payload = {
      code: code,
      value: value,
      varDefinitionId: varDefinitionId,
    };
    await addVariableMutateAsync(payload, {
      onSuccess: async () => {
        setIsAddDrawerOpen(false);
        notify.success({
          message: `You have successfully added code.`,
        });
        await queryClient.resetQueries({
          queryKey: ['variables'],
          type: 'active',
        });
      },
    });
  };
  return (
    <>
      <Sidepane
        ariaLabel={'Add Variable Code '}
        isOpen={isOpenDrawer}
        className="w-full sm:w-3/12 md:w-3/12"
      >
        <Sidepane.Header className="flex">
          <div className="flex text-xl font-semibold flex-grow">
            Add {category}
          </div>
          <IconButton onPress={() => setIsAddDrawerOpen(false)}>
            <BsX className="text-gray-600" />
          </IconButton>
        </Sidepane.Header>

        <form
          className="flex flex-col flex-grow mt-4"
          onSubmit={handleSubmit(() => handleChange())}
        >
          <div className="flex flex-col flex-grow">
            <Controller
              control={control}
              name="code"
              render={({ field }) => (
                <div>
                  <Label>
                    <p className="text-xs text-black">Code</p>
                  </Label>
                  <TextField
                    aria-label="code"
                    placeholder="Enter Code"
                    onChange={field.onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="value"
              render={({ field }) => (
                <div>
                  <Label>
                    <p className="text-xs text-black">Value</p>
                  </Label>
                  <TextField
                    label=""
                    aria-label="value"
                    placeholder="Enter Value"
                    onChange={field.onChange}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex md:flex-row flex-col items-end justify-end mt-6 gap-2">
            <Button
              variant="outline"
              color={'primary'}
              full={true}
              type="button"
              onPress={() => setIsAddDrawerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              color={'primary'}
              full={true}
              type={'submit'}
              isDisabled={!(code && value)}
            >
              Add
            </Button>
          </div>
        </form>
      </Sidepane>
    </>
  );
};
