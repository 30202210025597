import { AutoSearch, Chip, ChipGroup, Select } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import IncentiveListing from 'features/Incentive/components/IncentiveListing/IncentiveListing';
import { ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { Key, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

const IncentiveTypeFilter = ['All', 'Active', 'Inactive'];

export const IncentiveTable = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [plantId, setPlantId] = useState<string | null>(null);
  //location selection
  const { data: locationList, status: locationStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.LOCATION,
    sortBy: 'location',
    parentOrgId: !plantId ? undefined : plantId,
  });

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handlePayrollSelect = (val: Key) => {
    setPlantId(null);
    return val;
  };
  return (
    <Page
      breadcrumbs={[
        <NavLink key="ic-1" to="/incentives/incentive-table">
          Incentives
        </NavLink>,
      ]}
      heading={'Incentive Table'}
    >
      <Page.Section bg="gray">
        <div className="flex flex-col gap-3 lg:flex-row lg:items-center justify-between lg:gap-2">
          <div className="w-auto text-sm lg:w-64 flex-shrink-0">
            <AutoSearch
              id="search-incentive"
              aria-label="search-incentive"
              placeholder="Search Cal Group/Application Code"
              value={searchValue}
              onChange={(e) => {
                handleSearchChange(e);
              }}
            />
          </div>

          <Select
            items={locationList?.items}
            onSelectionChange={(e) => handlePayrollSelect(e)}
            placeholder="Payroll Location"
            isDisabled={locationStatus === 'pending'}
            className="w-full !text-gray-400 border-gray-400"
          ></Select>
          <div className="lg:h-8 lg:border-r-2 border-gray-300 mx-2"></div>

          <div className="sm:flex sm:flex-row w-full ">
            <div className="flex flex-row">
              <ChipGroup selectionMode="single" aria-label="IncentiveType">
                {IncentiveTypeFilter.map((item) => {
                  return (
                    <Chip key={item} size="large" id={item}>
                      {item}
                    </Chip>
                  );
                })}
              </ChipGroup>
            </div>
          </div>
          <div className="flex lg:justify-center w-full ">
            <NavLink
              className="flex flex-row justify-center items-center"
              to={'/incentives/incentive-table/add-calc-group'}
            >
              <div className="text-primary-400 font-semibold flex items-center mx-2">
                <BsPlus size="2rem" />
                <span>Add Cal group</span>
              </div>
            </NavLink>
          </div>
        </div>
      </Page.Section>
      <Page.Section>
        <IncentiveListing />
      </Page.Section>
    </Page>
  );
};
