import React, { useEffect, useRef, useState } from 'react';
import Page from 'components/Layouts/Page';
import { useEmployeeCrews, useEmployeeOrgs } from 'features/org/api';
import { usePersonDetails } from 'features/people/api';
import { useEmployeeTimecard } from 'features/timecards/api/getTimecardDetails';
import { CrewSelect } from 'features/timecards/components/Filters/CrewSelect';
import EmployeeDetails from 'features/timecards/components/TimecardDetails/Details/EmployeeDetails';
import WeekSelect from 'features/timecards/components/Filters/WeekSelect';
import AdjustmentSelect from 'features/timecards/components/Filters/AdjustmentSelect';
import useGenerateTimecard from 'features/timecards/hooks/useGenerateTimecard';
import useTimecardAccess from 'features/timecards/hooks/useTimecardAccess';
import DetailsSkeleton from 'components/Skeleton/DetailsSkeleton';
import { useParams } from 'react-router-dom';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import Tabs, { TabLinkType } from 'components/Tabs/Tabs';
import PolicySummary from 'features/timecards/components/Tabs/PolicySummary';
import TimecardHistory from 'features/timecards/components/Tabs/TimecardHistory';
import TimecardApprovals from 'features/timecards/components/Tabs/TimecardApprovals';
import ViewAllCrews from 'features/timecards/components/Tabs/ViewAllCrews';
import {
  TabTypes,
  SchedulingDetailsTabs,
  CrewEmployeeType,
} from 'features/timecards/types';

import { getDates } from 'features/timecards/utilities/getDates';
import { usePayPeriodStatus } from 'features/pay-periods/api';
import useWindowSize from 'hooks/useWindowSize';
import { ORG_TYPE } from 'features/org/constants';
import { SortDirection } from 'types/common';
import { HolidayType } from 'features/holidays';
import { SwipeTimes } from 'features/swipe-times/SwipeTimes';
import { AnnualTotals } from 'features/paid-time-off/components/AnnualTotals/AnnualTotals';
import { useSetPrimaryCrewPtos } from 'features/timecards/hooks/useSetPrimaryCrewPtos';

const MySchedule = () => {
  const state = useNonUnionStore();

  const { width } = useWindowSize();

  const { ussId = '' } = useParams<'ussId'>();

  // Adjustment State
  const { adjustment, setAdjustment } = state;

  // Person details for details card
  const { data, status } = usePersonDetails(ussId, {
    week: state.params.week ?? '',
  });
  const [holidayType, setHolidayType] = useState<HolidayType>('union');
  useEffect(() => {
    if (status === 'success') {
      setHolidayType(data.holidayType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status]);

  const shiftAssignment = data ? data.shiftAssignment : null;

  // Getting employee's's plant
  const { data: plantList, status: plantStatus } = useEmployeeOrgs('me', {
    type: ORG_TYPE.PLANT,
    sortBy: 'plant',
    sortType: SortDirection.ASC,
    role: 'crew_member,crew_temp_member',
    status: 'active',
  });

  //Getting employee crews
  const {
    data: employeePayCrews,
    status: crewStatus,
    isRefetching: isRefetchingEmployeeCrews,
  } = useEmployeeCrews(ussId, {
    week: state.params.week ?? '',
  });

  useEffect(() => {
    if (
      employeePayCrews &&
      employeePayCrews.items.length > 0 &&
      crewStatus === 'success'
    ) {
      const isgateSysytemAvailable =
        employeePayCrews.items[0]?.gateSystem?.available;
      state.setGateSystem(isgateSysytemAvailable);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeePayCrews, crewStatus]);

  // Determing if the user has access to the selected crew.
  const { isTimecardDisabled } = useTimecardAccess(
    employeePayCrews,
    state.crew?.id
  );

  // Employee timecards
  const {
    data: employeeTimecard,
    status: timecardStatus,
    isLoading,
  } = useEmployeeTimecard(
    ussId,
    {
      week: state.params.week ?? '',
      crewId: state.crew?.id,
      adjustment: adjustment?.toString(),
    },
    isRefetchingEmployeeCrews ? [] : employeePayCrews?.items
  );

  //Display loader initially until crews are loaded
  let shouldDisplayLoader = true;
  if (crewStatus === 'success') {
    //Setting shouldDisplayLoader to false if user does not have access to the crew selected
    shouldDisplayLoader =
      !!state.crew?.id &&
      !!employeePayCrews.items.find((crew) => crew.id === state.crew?.id);
  }
  const isFetchingTimecards = isLoading && shouldDisplayLoader;

  // Getting the first timecard in the list
  const timecard = employeeTimecard?.items[0];
  // Getting Payperiod status for the selected week and generating employeeTimecardObj
  const {
    weekBegin,
    weekEnd,
    dates = [],
  } = !!state.params.week &&
  state.selectedPlant?.weekBeginDayIndex !== undefined
    ? getDates({
        startDate: state.params.week,
        weekStartsOn: state.selectedPlant.weekBeginDayIndex,
        customFormat: 'yyyy-MM-dd',
      })
    : { weekBegin: null, weekEnd: null };

  const { data: payPeriodStatus } = usePayPeriodStatus(
    data?.payrollNameId ? data.payrollNameId : '',
    weekBegin ? weekBegin : '',
    weekEnd ? weekEnd : ''
  );
  const { employeeTimecardObj } = useGenerateTimecard({
    employeeTimecard: employeeTimecard,
    week: state.params.week,
    feature: 'my-schedule',
    payPeriodStatus: payPeriodStatus,
    dates: dates,
  });

  const versions = employeeTimecardObj.items[0].adjustmentVersions;

  if (
    plantStatus === 'success' &&
    plantList.items.length > 0 &&
    !!state.selectedPlant &&
    !Number.isInteger(state.selectedPlant.weekBeginDayIndex)
  ) {
    throw new Error("Plant's weekBeginDayIndex cannot be null/undefined");
  }
  useEffect(() => {
    state.setFeature('my-schedule');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      status === 'success' &&
      plantStatus === 'success' &&
      state.selectedPlant?.plantType !== data.plantType &&
      plantList.items.find((p) => p.plantType === data.plantType)
    ) {
      state.setPlant(plantList.items[0]);
    }
  }, [data?.plantType, plantList?.items, plantStatus, state, status]);

  useEffect(() => {
    if (
      plantStatus === 'success' &&
      plantList.items.length > 0 &&
      !plantList.items.find(
        (p) =>
          !!p.id && !!state.selectedPlant?.id && p.id === state.selectedPlant.id
      )
    ) {
      state.setPlant(plantList.items[0]);
    }
  }, [plantList, plantStatus, state]);
  //If the current Crew's plant doesn't match with the selected plant, set it to the selected crew's plant
  useEffect(() => {
    if (
      !!state.selectedPlant &&
      !!state.crew &&
      state.selectedPlant.plant !== state.crew.plant
    ) {
      const foundPlant = plantList?.items.find(
        (p) => !!p.plant && !!state.crew?.plant && p.plant === state.crew.plant
      );
      if (foundPlant) {
        state.setPlant(foundPlant);
      }
    }
  }, [state, state.crew, plantList?.items]);
  //Setting default adjustment
  //Reset persisted adjustment to null on first render
  useEffect(() => {
    if (adjustment !== null) {
      setAdjustment(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if there is an adjustment and set it to the most recent one.
  useEffect(() => {
    if (timecardStatus === 'success') {
      if (adjustment === null) {
        setAdjustment(versions[versions.length - 1]);
      }
    }
  }, [timecardStatus, adjustment, versions, setAdjustment]);

  // Determine if the user is a temp member of the selected Crew
  const isTemp =
    !!employeeTimecard &&
    employeeTimecard.items[0] &&
    employeeTimecard.items[0].employeeType === CrewEmployeeType.TEMP;

  // Tabs
  const currentTab = useRef<SchedulingDetailsTabs>(
    TabTypes.TIMECARD_SCHEDULING
  );
  const handleTabClick = (label: SchedulingDetailsTabs) => {
    //Setting current tab selection to ref on tab click
    currentTab.current = label;
  };

  //Getting startYear and endYear from selected week
  const startYear = weekBegin && new Date(weekBegin).getFullYear().toString();
  const endYear = weekEnd && new Date(weekEnd).getFullYear().toString();

  const tabLinks: TabLinkType[] = [
    {
      label: TabTypes.TIMECARD_SCHEDULING,
      element: (
        <TimecardApprovals
          isFetchingTimecards={isFetchingTimecards}
          employeeTimecardObj={employeeTimecardObj}
          isTimecardDisabled={isTimecardDisabled}
          shiftAssignment={shiftAssignment}
          setAdjustment={setAdjustment}
          width={width}
          holidayType={holidayType}
        />
      ),
      customOnChange: () => handleTabClick(TabTypes.TIMECARD_SCHEDULING),
    },
    {
      label: TabTypes.HISTORY,
      element: <TimecardHistory id={timecard?.id ?? ''} />,
      customOnChange: () => handleTabClick(TabTypes.HISTORY),
    },
    {
      label: TabTypes.POLICY_SUMMARY,
      element: (
        <PolicySummary
          ussID={ussId}
          week={state.params.week}
          holidayType={holidayType}
          width={width}
        />
      ),
      customOnChange: () => handleTabClick(TabTypes.POLICY_SUMMARY),
    },
    {
      label: TabTypes.VIEW_ALL_CREWS,
      element: <ViewAllCrews />,
      customOnChange: () => handleTabClick(TabTypes.VIEW_ALL_CREWS),
    },
    {
      label: TabTypes.SWIPE_TIMES,
      element: <SwipeTimes ussID={ussId} week={state.params.week} />,
      customOnChange: () => handleTabClick(TabTypes.SWIPE_TIMES),
      isDisabled: state.crew?.gateSystem.available === 'n' ? true : false,
    },
    {
      label: TabTypes.ANNUAL_PTO_TOTALS,
      element: (
        <div className="gap-5">
          {startYear && startYear !== endYear && (
            <AnnualTotals year={startYear} id={ussId} />
          )}
          {endYear && <AnnualTotals year={endYear} id={ussId} />}
        </div>
      ),
      customOnChange: () => handleTabClick(TabTypes.ANNUAL_PTO_TOTALS),
    },
  ];

  /** Saving Primary Crew Ptos to Store**/
  useSetPrimaryCrewPtos(ussId);

  return (
    <Page heading={'My Schedule (NU)'}>
      <Page.Section>
        {status !== 'success' ? (
          <DetailsSkeleton />
        ) : (
          <EmployeeDetails employee={data} temp={isTemp} />
        )}
      </Page.Section>
      <Page.Section>
        <div className="flex flex-row flex-wrap px-3">
          <div className="w-full sm:w-4/5">
            <Page.Filters>
              <h3 className="sr-only">Timecard Filters</h3>
              <Page.FiltersSection className="w-full sm:w-1/2 2xl:w-4/12">
                <WeekSelect
                  startDayOfWeek={state.selectedPlant?.weekBeginDayIndex}
                  plantType={state.selectedPlant?.plantType}
                />
              </Page.FiltersSection>
              <Page.FiltersSection className="w-full sm:w-1/4 2xl:w-4/12">
                <CrewSelect
                  crews={employeePayCrews?.items ? employeePayCrews.items : []}
                  selectedCrew={state.crew}
                  setCrew={state.setCrew}
                  crewStatus={crewStatus}
                />
              </Page.FiltersSection>
              <Page.FiltersSection className="w-full sm:w-1/5 2xl:w-1/6">
                <AdjustmentSelect
                  selected={adjustment}
                  hasTimecard={!!timecard}
                  adjustments={timecard ? timecard.adjustmentVersions : [0]}
                  setAdjustment={setAdjustment}
                  status={
                    crewStatus === 'success' &&
                    employeePayCrews.items.length === 0
                      ? 'success'
                      : timecardStatus
                  }
                />
              </Page.FiltersSection>
            </Page.Filters>
          </div>
        </div>
        <div className="mt-2">
          <Tabs tabLinks={tabLinks} />
        </div>
      </Page.Section>
    </Page>
  );
};

export default MySchedule;
