import { Button, DatePicker, Select, TextField } from '@uss/react-core';

export const PositionCodeForm = () => {
  return (
    <div className="bg-white pt-4 sm:bg-white rounded-md mb-2 pb-4 px-3">
      <div className="grid grid-col gap-6 md:w-3/4 mx-auto justify-center">
        <h2 className="items-start font-bold">General Details</h2>

        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
          <div className=" flex flex-row gap-2 w-full md:w-1/2">
            <div className="w-1/2">
              <Select label="Payroll Location" placeholder="Select" />
            </div>
            <div className="w-1/2">
              <Select label="Position Code" placeholder="Select" />
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <TextField
              label="Position Name"
              type="text"
              placeholder="Enter Text"
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
          <div className="flex flex-row gap-2 w-full md:w-1/2">
            <Select
              className="w-full"
              label="Bargaining Unit"
              placeholder="Select"
            />
            <Select
              className="w-full"
              label="Labor Grade"
              placeholder="Select"
            />
          </div>
          <div className="w-full md:w-1/2">
            <Select label="Job Description" placeholder="Select" />
          </div>
        </div>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
          <Select className="w-full" label="Craft Code" placeholder="Select" />
          <Select className="w-full" label="Rate Code" placeholder="Select" />
        </div>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
          <div className="w-full md:w-1/2">
            <DatePicker label="Effective Date"></DatePicker>
          </div>
          <div className="w-full md:w-1/2">
            <DatePicker label="End Date"></DatePicker>
          </div>
        </div>
        <div className="border-t-2 mt-4 border-gray-300" />

        <h2 className="items-start mt-4 font-bold">Payroll Details</h2>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
          <div className="flex flex-row gap-2 w-full md:w-1/2">
            <div className="w-full">
              <TextField
                label="Standard Hourly Rate"
                type="text"
                placeholder="Enter Text"
              />
            </div>
            <div className="w-full">
              <TextField
                label="Converted Base Rate"
                type="text"
                placeholder="Enter Text"
              />
            </div>
          </div>
          <div className="w-1/2 md:w-1/4">
            <TextField label="OLD Rate" type="text" placeholder="Enter Text" />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="w-1/2">
            <TextField
              label="Starter Hourly Rate"
              type="text"
              placeholder="Enter Text"
            />
          </div>
          <div className="w-1/2">
            <TextField
              label="Starter Converted Base Rate"
              type="text"
              placeholder="Enter Text"
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap w-full ">
          <div className="flex flex-row gap-2 w-full md:w-1/2">
            <Select
              className="w-full"
              label="Default Start Time"
              placeholder="Select"
            />
            <Select
              className="w-full"
              label="Standard Shift Length"
              placeholder="Select"
            />
          </div>
          <div className="w-1/2 md:w-1/4">
            <Select label="Buddy Relief" placeholder="Select" />
          </div>
        </div>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
          <div className="flex flex-row gap-2 w-full md:w-1/2">
            <Select
              className="w-full"
              label="Calc Group"
              placeholder="Select"
            />
            <Select
              className="w-full"
              label="Application Code"
              placeholder="Select"
            />
          </div>
          <div className="w-full md:w-1/2">
            <Select label="Incentive Plan Type" placeholder="Select" />
          </div>
        </div>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap w-full">
          <div className="flex flex-row gap-2 w-full md:w-1/2">
            <Select
              className="w-full"
              label="Wash up Indicator"
              placeholder="Select"
            />
            <Select
              className="w-full"
              label="Auto OM code"
              placeholder="Select"
            />
          </div>
          <div className="w-1/2 md:w-1/4">
            <Select label="Service Bonus Pool" placeholder="Select" />
          </div>
        </div>
        <div className="border-t-2 mt-4 border-gray-300" />

        <h2 className="items-start mt-4 font-bold">PI Details</h2>
        <div className="flex flex-row gap-4 flex-wrap md:flex-nowrap">
          <div className="w-full md:w-1/2">
            <Select label="Position Type" placeholder="Select" />
          </div>
          <div className="flex flex-row gap-4 w-full md:w-1/2">
            <Select
              className="w-full"
              label="Crew Leader"
              placeholder="Select"
            />
            <Select
              className="w-full"
              label="Discipline"
              placeholder="Select"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 flex-wrap md:flex-nowrap">
          <Select className="w-full" label="Division" placeholder="Select" />
          <Select className="w-full" label="Department" placeholder="Select" />
        </div>
        <div className="flex flex-row gap-4 flex-wrapmd:flex-nowrap md:w-1/2 pr-2">
          <Select className="w-full " label="DRO Code" placeholder="Select" />
        </div>
        <div className="flex flex-row gap-4 flex-wrap md:flex-nowrap">
          <Select
            className="w-full"
            label="Seniority Unit"
            placeholder="Select"
          />
          <Select className="w-full" label="LOP" placeholder="Select" />
        </div>
        <div className="flex flex-row md:w-1/2 gap-4 pr-2 flex-wrap md:flex-nowrap">
          <Select className="w-full" label="Suffix" placeholder="Select" />
        </div>
        <div className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
          <div className="flex flex-row gap-4 w-full md:w-1/2">
            <div className="flex flex-col w-full">
              <TextField label="Direct" type="text" placeholder="Enter Text" />
            </div>
            <div className="flex flex-col w-full pr-2">
              <TextField label="Support" type="text" placeholder="Enter Text" />
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 pr-2">
            <TextField
              label="Authorized"
              type="text"
              placeholder="Enter Text"
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap md:flex-nowrap pr-2">
          <div className="md:w-1/2 w-full">
            <TextField
              label="Assignment Group"
              type="text"
              placeholder="Enter Text"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-2 mt-4 justify-end">
        <Button variant="outline">Cancel</Button>
        <Button>Add</Button>
      </div>
    </div>
  );
};
