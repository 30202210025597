import { Button, Checkbox, CheckboxGroup } from '@uss/react-core';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { EditModelReqBody } from 'features/model-listing/types';
import { useState } from 'react';
interface DeleteWeeksProps {
  setOpenDrawer: (val: boolean) => void;
  openDrawer: boolean;
  setOpenDeleteWeeks: (val: boolean) => void;
  setDeleteWeeksPayload: (val: EditModelReqBody) => void;
}

export const DeleteWeeks = ({
  setOpenDrawer,
  setOpenDeleteWeeks,
  setDeleteWeeksPayload,
}: DeleteWeeksProps) => {
  const store = useModelDetailStore();

  const weeksArray = store.modelDetail?.weeks;
  const WeekNumbers = weeksArray?.map((weekNum) => weekNum.weekNo.toString());
  const weekOptionsWithAll = ['all', ...(WeekNumbers ?? [])];
  const [checked, setChecked] = useState<string[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

  const handleOnChange = (val: string[]) => {
    if (val.filter((e) => e !== 'all').length === WeekNumbers?.length) {
      setIsSelectedAll(true);
      setChecked(['all', ...val]);
    } else {
      setIsSelectedAll(false);
      setChecked(val.filter((e) => e !== 'all'));
    }
  };
  const onSelectAllHandle = (val: boolean) => {
    if (val) {
      setChecked(weekOptionsWithAll);
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
      setChecked([]);
    }
  };

  const handleDelete = () => {
    let payload: EditModelReqBody = {
      modelDescription: '',
      action: '',
    };
    if (checked.length === store.modelDetail?.weeks.length) {
      payload = {
        modelDescription: store.modelDetail.modelDescription,
        action: 'save',
        weeks: [],
      };
    } else {
      const weeksAfterDelete = store.modelDetail?.weeks.filter(
        (week) => !checked.includes(week.weekNo.toString())
      );

      const removedDeletedWeeks = weeksAfterDelete?.map((dw, index) => {
        return {
          weekNo: index + 1,
          crewRotations: dw.crewRotations,
        };
      });

      payload = {
        modelDescription: store.modelDetail?.modelDescription ?? '',
        action: 'save',
        weeks: removedDeletedWeeks,
      };
    }
    setDeleteWeeksPayload(payload);
    setOpenDeleteWeeks(true);
  };

  const handleCancel = () => {
    setOpenDrawer(false);
  };

  return (
    <form className="flex flex-col flex-grow">
      <div className="w-full flex flex-col flex-grow gap-5 ">
        <CheckboxGroup
          orientation="vertical"
          label="Select the week(s) you want to delete"
          value={checked}
          onChange={(e) => handleOnChange(e)}
        >
          <Checkbox
            isIndeterminate={isSelectedAll}
            isSelected={isSelectedAll}
            value="all"
            onChange={(e) => onSelectAllHandle(e)}
          >
            Select All
          </Checkbox>
          {WeekNumbers?.map((week, index) => (
            <Checkbox
              key={index}
              value={week.toString()}
            >{`Weeks ${week}`}</Checkbox>
          ))}
        </CheckboxGroup>
      </div>

      <div className="flex justify-between gap-2 pt-6">
        <Button
          variant="outline"
          onPress={handleCancel}
          color={'primary'}
          full={true}
          type="button"
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          color={'danger'}
          full={true}
          type={'button'}
          onPress={handleDelete}
        >
          Delete
        </Button>
      </div>
    </form>
  );
};
